import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index';

//  认证服务占比
export const getCertOfServers = (params) =>
  get(`${requestUrl}/home/certOfServers`, { ...params }).then(
    (res) => res.data
  );

export const getCertOfServerss = createAsyncThunk(
  'overview/getCertOfServerss',
  async (params) => {
    const res = await getCertOfServers(params);
    return res;
  }
);

//  用户统计数
export const getTopList = (params) =>
  get(`${requestUrl}/v2/web/goods/statistics`, { ...params }).then(
    (res) => res.data
  );

export const getTopLists = createAsyncThunk(
  'overview/getTopLists',
  async (params) => {
    const res = await getTopList(params);
    return res;
  }
);

//  运营数据统计
export const getOperation = (params) =>
  get(`${requestUrl}/home/homeTjList`, { ...params }).then((res) => res.data);

export const getOperations = createAsyncThunk(
  'overview/getOperations',
  async (params) => {
    const res = await getOperation(params);
    return res;
  }
);

//  运营数据统计-企业用户
export const getAdminOperation = (params) =>
  get(`${requestUrl}/v2/web/chainStatus/interactStatistical`, {
    ...params
  }).then((res) => res.data);

export const getAdminOperations = createAsyncThunk(
  'overview/getAdminOperations',
  async (params) => {
    const res = await getAdminOperation(params);
    return res;
  }
);

//  互动次数统计
export const getInteraction = (params) =>
  get(`${requestUrl}/v2/web/interact/interactStatistical`, { ...params }).then(
    (res) => res.data
  );

export const getInteractions = createAsyncThunk(
  'overview/getInteractions',
  async (params) => {
    const res = await getInteraction(params);
    return res;
  }
);

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    serverData: null,
    topList: null,
    operationData: null,
    adminOperationData: null,
    interactionData: null
  },
  reducers: {},
  extraReducers: {
    [getCertOfServerss.fulfilled](state, { payload }) {
      state.serverData = payload;
    },
    [getTopLists.fulfilled](state, { payload }) {
      state.topList = payload;
    },
    [getOperations.fulfilled](state, { payload }) {
      state.operationData = payload;
    },
    [getAdminOperations.fulfilled](state, { payload }) {
      state.adminOperationData = payload;
    },
    [getInteractions.fulfilled](state, { payload }) {
      state.interactionData = payload;
    }
  }
});

export default overviewSlice.reducer;
