import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
import { requestUrl } from '@/config/index';

// 申请认证分页
export const getApplyList = (params) =>
  get(`${requestUrl}/v2/web/goods/applyCertRecord`, { ...params }).then(
    (res) => res.data
  );

export const getApplyLists = createAsyncThunk(
  'record/applyCertRecord',
  async (params) => {
    const res = await getApplyList(params);
    return res;
  }
);
// 申请认证批量导出
export const getApplyCert = (params) =>
  post(`${requestUrl}/v2/web/goods/importApplyCert`, { ...params }, {responseType: 'blob'}).then(
    (res) => res
  );

// 宝贝转移分页
export const getTransferList = (params) =>
  get(`${requestUrl}/v2/web/goods/transferRecord`, { ...params }).then(
    (res) => res.data
  );
export const getTransferLists = createAsyncThunk(
  'record/transferRecord',
  async (params) => {
    const res = await getTransferList(params);
    return res;
  }
);

// 宝贝转移量导出
export const getImportTransfer = (params) =>
  post(`${requestUrl}/v2/web/goods/importTransfer`, { ...params }, {responseType: 'blob'}).then(
    (res) => res
  );

// 宝贝销毁
export const getDestroyList = (params) =>
  get(`${requestUrl}/v2/web/goods/destroyRecord`, { ...params }).then(
    (res) => res.data
  );
export const getDestroyLists = createAsyncThunk(
  'record/destroyRecord',
  async (params) => {
    const res = await getDestroyList(params);
    return res;
  }
);

// 宝贝转移导出
export const getImportDestroy = (params) =>
  post(`${requestUrl}/v2/web/goods/importDestroy`, { ...params }, {responseType: 'blob'}).then(
    (res) => res
  );

// 向上追溯分页
export const getUpTraceList = (params) =>
  get(`${requestUrl}/v2/web/goods/upTraceRecord`, { ...params }).then(
    (res) => res.data
  );
export const getUpTraceLists = createAsyncThunk(
  'record/upTraceRecord',
  async (params) => {
    const res = await getUpTraceList(params);
    return res;
  }
);

//  向上追溯导出
export const getImportUpTrace = (params) =>
  post(`${requestUrl}/v2/web/goods/importUpTrace`, { ...params }, {responseType: 'blob'}).then(
    (res) => res
  );

// 撤销转移宝贝
export const getRevokeTransfer = (params) =>
  post(`${requestUrl}/v2/web/goods/revokeTransfer`, { ...params }).then(
    (res) => res
  );

// 编辑向上溯源
export const getEditUpTrace = (params) =>
  post(`${requestUrl}/v2/web/goods/editUpTrace`, { ...params }).then(
    (res) => res
  );

export const operationRecordSlice = createSlice({
  name: 'operationRecord',
  initialState: {
    applytList: [],
    applyTotal: 0,
    transferList: [],
    transferTotal: 0,
    destroyList: [],
    destroyTotal: 0,
    upTraceList:[],
    upTraceTotal:0
  },
  reducers: {},
  extraReducers: {
    // 申请认证
    [getApplyLists.fulfilled](state, { payload }) {
      state.applytList = payload?.records ? payload?.records : [];
      state.applyTotal = payload?.total ? payload?.total : 0;
    },
    [getApplyLists.rejected]() {},
    [getApplyLists.pending]() {},

    // 宝贝转移
    [getTransferLists.fulfilled](state, { payload }) {
      state.transferList = payload?.records ? payload?.records : [];
      state.transferTotal = payload?.total ? payload?.total : 0;

    },
    [getDestroyLists.rejected]() {},
    [getDestroyLists.pending]() {},
    // 宝贝销毁
    [getDestroyLists.fulfilled](state, { payload }) {
      state.destroyList = payload?.records ? payload?.records : [];
      state.destroyTotal = payload?.total ? payload?.total : 0;

    },
    [getTransferLists.rejected]() {},
    [getTransferLists.pending]() {},
    // 向上追溯
    [getUpTraceLists.fulfilled](state, { payload }) {
      state.upTraceList = payload?.records ? payload?.records : [];
      state.upTraceTotal = payload?.total ? payload?.total : 0;
    },
    [getUpTraceLists.rejected]() {},
    [getUpTraceLists.pending]() {},
  }
});

export default operationRecordSlice.reducer;
