const defaultUserInfo = {
  data: {
    permissions: {},
    access_token: '',
    token_type: '',
    refresh_token: '',
    expires_in: '',
    scope: 'all',
    tenant_id: '',
    user_type: -1,
    user_id: '',
    role_id: '',
    user_name: '',
    nick_name: '',
    oauth_id: '',
    avatar: '',
    detail: {
      type: 'web'
    },
    client_id: '',
    account: '',
    status: -1,
    jti: ''
  },
  userLoading: false
};

export default defaultUserInfo;
