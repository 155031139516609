import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
import loginInfo from '../../pages/operator/log/config/loginInfo';

/* 公共接口的调用 */
const dictDetailApi = (params) =>
  post(
    'http://localhost:3000/console-mock/mock/26a30523-bae2-4ed9-a1e8-696464c0099c/dict?apipost_id=547fef',
    params
  ).then((res) => res.data);

const logListApi = (params) =>
  post(
    'http://localhost:3000/console-mock/mock/26a30523-bae2-4ed9-a1e8-696464c0099c/list?apipost_id=375094',
    params
  ).then((res) => res.data);

const logDetailApi = (params) =>
  get(
    'http://localhost:3000/console-mock/mock/26a30523-bae2-4ed9-a1e8-696464c0099c/detail?apipost_id=2f0e50',
    params
  ).then((res) => res.data);

// 名称  解决异步请求接口使用 类似于promise 里面有fulfilled reject pending三种状态
export const fetchDictTopData = createAsyncThunk(
  'dictType/loadData',
  async (params) => {
    const res = await dictDetailApi(params);
    return {
      ...params,
      res
    };
  }
);

export const fetchLogListData = createAsyncThunk(
  'logLogin/loadData',
  async (params) => {
    const res = await logListApi(params);
    return res;
  }
);
export const fetchLogDetail = createAsyncThunk(
  'logDetail/loadData',
  async (params) => {
    const res = await logDetailApi(params);
    return res;
  }
);

export const logLogin = createSlice({
  name: 'topMenu',
  initialState: {
    typeData: [],
    appData: [],
    logData: [],
    selectedKeys: '',
    pagination: {
      total: 0,
      pageSize: 10,
      current: 1,
      showJumper: true,
      showTotal: true,
      hideOnSinglePage: true,
      pageSizeChangeResetCurrent: true,
      style: { margin: '16px 20px 16px' }
    },
    logDetail: []
  },
  reducers: {
    getDictType(state, { payload }) {
      state.typeData = payload;
    },
    getLogList(state, { payload }) {
      state.logData = payload;
    },
    getPagination(state, { payload }) {
      state.pagination = payload;
    }
  },

  // 可以关联其他reducer
  extraReducers: {
    // [changeName](state, { payload }) {
    //   console.log('extraReducers', state, payload);
    //   state.list.push(1);
    //   state.totals += 1;
    // },
    [fetchDictTopData.fulfilled](state, { payload }) {
      console.log(payload);
      const data = payload.res.map((item) => {
        item.dictKey = Number(item.dictKey);
        return item;
      });
      data.unshift({
        dictKey: '',
        dictValue: '全部'
      });
      if (payload.code === 'operationType') {
        state.typeData = data;
      } else if (payload.code === 'appType') {
        state.appData = data;
      }
    },
    [fetchDictTopData.rejected](state, err) {
      console.log('loadData.reject', err);
    },
    [fetchDictTopData.pending]() {
      // console.log('pending', state);
    },
    [fetchLogListData.fulfilled](state, { payload }) {
      state.logData = payload.records;
      state.pagination.total = payload.total;
    },
    [fetchLogDetail.fulfilled](state, { payload }) {
      console.log(payload, '----------------');
      state.logDetail = loginInfo.details(
        payload,
        state.typeData,
        state.appData
      );
    }
  }
});

export const { getDictType, getLogList, getPagination } = logLogin.actions;
export default logLogin.reducer;
