import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  字典类型
export const getDictTypeList = (params) =>
  get(
    `${requestUrl}/dict/type/dictList`,
    { ...params }
  ).then((res) => res.data);

export const getDictTypeLists = createAsyncThunk(
  'dataDictionary/getDictTypeLists',
  async (params) => {
    const res = await getDictTypeList(params);
    return res;
  }
);


export const dataDictionarySlice = createSlice({
  name: 'dataDictionary',
  initialState: {
    typeList: [],
    status:0
  },
  reducers: {
    setTypeListFtn(state, { payload }) {
        let list = state.typeList
        list.forEach(item=>{
            item.status = false
        })
        list[payload.index].status = true
        state.typeList = list;
    },
    setStatusFtn(state, {payload}){
      console.log(payload);
      state.status = 0
    }
},
  extraReducers: {
    [getDictTypeLists.fulfilled](state, { payload }) {
      state.typeList = payload ? payload?.map((item,index)=>{
        return {
            id:item.id,
            status:index === 0 ? true : false,
            dictName:item.dictName
        }
      }) : []
      state.status = 1
    },
    [getDictTypeLists.rejected]() { },
    [getDictTypeLists.pending]() { },

  }
});

export const { setTypeListFtn, setStatusFtn } = dataDictionarySlice.actions;
export default dataDictionarySlice.reducer;
