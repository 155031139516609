import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
import { requestUrl } from '@/config/index'

//  角色权限
export const getUsersList = (params) =>
  get(
    `${requestUrl}/users/list`,
    { ...params }
  ).then((res) => res.data);

  export const getUsersList2 = (params) =>
  get(
    `${requestUrl}/users/listBySpecial`,
    { ...params }
  ).then((res) => res.data);

export const getUsersLists = createAsyncThunk(
  'role/getUsersList',
  async (params) => {
    const res = await getUsersList(params);
    return res;
  }
);
export const getUsersLists2 = createAsyncThunk(
  'role/getUsersList2',
  async (params) => {
    const res = await getUsersList2(params);
    return res;
  }
);

export const getUnList = (params) =>
  get(
    `${requestUrl}/users/unList`,
    { ...params }
  ).then((res) => res.data);

export const getUnLists = createAsyncThunk(
  'role/getUnLists',
  async (params) => {
    const res = await getUnList(params);
    return res;
  }
);
export const getUsersVerifyIsEmail = (params) =>
  post(
    `${requestUrl}/users/verifyIsEmail`,
    { ...params }
  ).then((res) => res.data);

export const getUsersVerifyIsEmails = createAsyncThunk(
  'role/getUsersVerifyIsEmail',
  async (params) => {
    const res = await getUsersVerifyIsEmail(params);
    return res;
  }
);


export const autonymUserSlice = createSlice({
  name: 'autonymUser',
  initialState: {
    userList: [],
    userTotal: 0,
    userList2: [],
    userTotal2: 0,
    unUserList:[],
    unUserTotal: 0,
    emailStatus: true
  },
  reducers: {},
  extraReducers: {
    [getUsersLists.fulfilled](state, { payload }) {
      state.userList = payload?.records ? payload?.records : []
      state.userTotal = payload?.total ? payload?.total : 0
    },
    [getUsersLists.rejected]() { },
    [getUsersLists.pending]() { },
    [getUsersLists2.fulfilled](state, { payload }) {
      state.userList2 = payload?.records ? payload?.records : []
      state.userTotal2 = payload?.total ? payload?.total : 0
    },
    [getUsersLists2.rejected]() { },
    [getUsersLists2.pending]() { },
    [getUnLists.fulfilled](state, { payload }) {
      state.unUserList = payload?.records ? payload?.records : []
      state.unUserTotal = payload?.total ? payload?.total : 0
    },
    [getUnLists.rejected]() { },
    [getUnLists.pending]() { },
    [getUsersVerifyIsEmails.fulfilled](state, { payload }) {
      state.emailStatus = payload
    },
    [getUsersVerifyIsEmails.rejected]() { },
    [getUsersVerifyIsEmails.pending]() { },

  }
});

export default autonymUserSlice.reducer;
