import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from '@arco-design/web-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GlobalContext } from './context';
import useStorage from './utils/useStorage';
import checkLogin from './utils/checkLogin';
import changeTheme from './utils/changeTheme';
// import { fetchUserInfoData } from '@/store/features/userInfoSlice';
import store from './store';
import Login from './pages/login';
import PageLayout from './layout';
import '@/style/global.less';
// import { getPublicKey } from '@/store/mineStore/pulicKeyStore';

function Index() {
  const [lang, setLang] = useStorage('arco-lang', 'en-US');
  const [theme, setTheme] = useStorage('arco-theme', 'light');

  useEffect(() => {
    if (checkLogin()) {
      // store.dispatch(fetchUserInfoData());
    } else if (window.location.pathname.replace(/\//g, '') !== 'login') {
      window.location.pathname = '/login';
    }
  }, []);

  useEffect(() => {
    // getPublicKey({
    //   code: 'sm2',
    //   dictKey: 'publicKey'
    // }).then((res) => {
    //   localStorage.setItem('miyao_publicKey', res);
    // });
  }, []);

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  const contextValue = {
    lang,
    setLang,
    theme,
    setTheme
  };

  return (
    <BrowserRouter>
      <ConfigProvider
        componentConfig={{
          Table: {
            border: false
          }
        }}
      >
        <Provider store={store}>
          <GlobalContext.Provider value={contextValue}>
            <Routes>
              <Route path='/login' element={<Login />} />
              {/* "前缀"匹配 */}
              <Route path='/*' element={<PageLayout />} />
            </Routes>
            {/* <App /> */}
          </GlobalContext.Provider>
        </Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<Index />);
