import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  新增藏品
export const getBannerList = (params) =>
  get(
    `${requestUrl}/banner/list`,
    { ...params }
  ).then((res) => res.data);
export const getBannerLists = createAsyncThunk(
  'btn/getBannerLis',
  async (params) => {
    const res = await getBannerList(params);
    return res;
  }
);

export const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    bannerList:[]
  },
  reducers: {},
  extraReducers: {
    [getBannerLists.fulfilled](state, { payload }) {
      state.bannerList = payload?.records ? payload?.records : []
    },
    [getBannerLists.rejected]() { },
    [getBannerLists.pending]() { },
  }
});

export default bannerSlice.reducer;
