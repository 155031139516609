import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  认证服务占比
export const getCertOfServers = (params) =>
    get(
        `${requestUrl}/home/certOfServers`,
        { ...params }
    ).then((res) => res.data);

export const getCertOfServerss = createAsyncThunk(
    'home/getCertOfServerss',
    async (params) => {
        const res = await getCertOfServers(params);
        return res;
    }
);

//  用户统计数
export const getTopList = (params) =>
    get(
        `${requestUrl}/home/topList`,
        { ...params }
    ).then((res) => res.data);

export const getTopLists = createAsyncThunk(
    'home/getTopLists',
    async (params) => {
        const res = await getTopList(params);
        return res;
    }
);

//  运营数据统计
export const getOperation = (params) =>
    get(
        `${requestUrl}/home/homeTjList`,
        { ...params }
    ).then((res) => res.data);

export const getOperations = createAsyncThunk(
    'home/getOperations',
    async (params) => {
        const res = await getOperation(params);
        return res;
    }
);

//  运营数据统计-管理端
export const getAdminOperation = (params) =>
    get(
        `${requestUrl}/home/homeTjList`,
        { ...params }
    ).then((res) => res.data);

export const getAdminOperations = createAsyncThunk(
    'home/getAdminOperations',
    async (params) => {
        const res = await getAdminOperation(params);
        return res;
    }
);

//  互动次数统计
export const getInteraction = (params) =>
    get(
        `${requestUrl}/home/homeTjList`,
        { ...params }
    ).then((res) => res.data);

export const getInteractions = createAsyncThunk(
    'home/getInteractions',
    async (params) => {
        const res = await getInteraction(params);
        return res;
    }
);






export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        serverData: null,
        topList: null,
        operationData: null,
        adminOperationData: null,
        interactionData: null
    },
    reducers: {},
    extraReducers: {
        [getCertOfServerss.fulfilled](state, { payload }) {
            state.serverData = payload
        },
        [getTopLists.fulfilled](state, { payload }) {
            state.topList = payload
        },
        [getOperations.fulfilled](state, { payload }) {
            state.operationData = payload
        },
        [getAdminOperations.fulfilled](state, { payload }) {
            state.adminOperationData = payload
        },
        [getInteractions.fulfilled](state, { payload }) {
            state.interactionData = payload
        },
    }
});

export default homeSlice.reducer;
