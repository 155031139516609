import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index';

//  新增藏品
export const getTopicList = (params) =>
  get(`${requestUrl}/topic/list`, { ...params }).then((res) => res.data);
export const getTopicLists = createAsyncThunk(
  'speciaContent/getTopicLists',
  async (params) => {
    const res = await getTopicList(params);
    return res;
  }
);
// 宝贝列表
export const getCowrieList = (params) =>
  get(`${requestUrl}/cowrie/pcList`, { ...params }).then((res) => res.data);
export const getCowrieLists = createAsyncThunk(
  'speciaContent/getCowrieLists',
  async (params) => {
    const res = await getCowrieList(params);
    return res;
  }
);

// 商品列表
export const getProductList = (params, data) =>
  get(`${requestUrl}/v2/web/goods/pcTopicList`, { ...params }, {...data}).then(
    (res) => res.data
  );
export const getProductLists = createAsyncThunk(
  'speciaContent/getProductList',
  async (params, data) => {
    const res = await getProductList(params, data);
    return res;
  }
);

export const speciaContentSlice = createSlice({
  name: 'speciaContent',
  initialState: {
    speciaList: [],
    speciaTotal: 0,
    cowrieList: [],
    cowrieTotal: 0,
    productList: [],
    productTotal: 0
  },
  reducers: {},
  extraReducers: {
    [getTopicLists.fulfilled](state, { payload }) {
      state.speciaList = payload?.records ? payload?.records : [];
      state.speciaTotal = payload?.total ? payload.total : 0;
    },
    [getTopicLists.rejected]() {},
    [getTopicLists.pending]() {},
    [getCowrieLists.fulfilled](state, { payload }) {
      state.cowrieList = payload?.records ? payload?.records : [];
      state.cowrieTotal = payload?.total ? payload.total : 0;
    },
    [getProductLists.fulfilled](state, { payload }) {
      state.productList = payload?.records ? payload?.records : [];
      state.productTotal = payload?.total ? payload.total : 0;
    },
    [getCowrieLists.rejected]() {},
    [getCowrieLists.pending]() {}
  }
});

export default speciaContentSlice.reducer;
