import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { changeName } from './userSlice';
import { get, post } from '@/api/request';
export const Interface = (params) =>
  get(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/details?apipost_id=e455ef',
    { ...params }
  ).then((res) => res);
const AppliCationApi = (params) =>
  post(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/web/operate/dict/getTypeList/all?apipost_id=6ee553',
    { ...params }
  ).then((res) => res);
const operateListApi = (params) =>
  post(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/web/operate/dict/list?apipost_id=2170bb',
    { ...params }
  ).then((res) => res);
const nodeDetailApi = (params) =>
  get(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/ReportTestDetail?apipost_id=d3f1c5',
    { ...params }
  ).then((res) => res);
export const fambelldelete = (params) =>
  post(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/web/operate/dict/delete?apipost_id=2785f7',
    { ...params }
  ).then((res) => res);
// 名称  解决异步请求接口使用 类似于promise 里面有fulfilled reject pending三种状态
export const journal = createAsyncThunk(
  'dictionary/journal',
  async (params) => {
    const res = await operateListApi(params);
    return res;
  }
);
export const detailsPanelData = createAsyncThunk(
  'toDictionary/journal',
  async (params) => {
    const res = await AppliCationApi(params);
    return res;
  }
);
export const getTypeList = createAsyncThunk(
  'getTypeList/journal',
  async (params) => {
    const res = await AppliCationApi(params);
    return res;
  }
);
export const nodeDetailList = createAsyncThunk(
  'nodeDetailList/journal',
  async (params) => {
    const res = await nodeDetailApi(params);
    return res;
  }
);

export const dictionaryis = createSlice({
  name: 'dictionary',
  initialState: {
    state: {
      border: false,
      borderCell: false,
      hover: true,
      stripe: false,
      showHeader: false,
      fixedHeader: false,
      no_data: false,
      size: 'default',
      pagination: false,
      pagePosition: 'br'
    }, // Table配置项
    state1: {
      border: true,
      borderCell: false,
      hover: true,
      stripe: false,
      showHeader: true,
      fixedHeader: false,
      no_data: false,
      size: 'default',
      pagination: false,
      pagePosition: 'br'
    }, // Table配置项
    defaultData: [], // 左侧列表数据展示
    dataset: [], // 右侧列表
    ReportTestDetail: {}, // 右上侧详情
    mState: [],
    getType: [], // 状态选项
    loading: false, // 加载中
    loading1: false, // 加载中
    StateA: {},
    total: 0
  },
  reducers: {
    loadDataEnd(state, { payload }) {
      state.StateA = payload;
    }
  },

  // 可以关联其他reducer
  extraReducers: {
    // [changeName](state, { payload }) {
    //   console.log('extraReducers', state, payload);
    //   state.list.push(1);
    //   state.total += 1;
    // },
    [getTypeList.fulfilled](state, { payload }) {
      const { data } = payload;
      let firstList = [];
      firstList = data.map((item) => {
        item.dictKey = Number(item.dictKey);
        return item;
      });
      state.mState = data.map((item) => {
        return item.dictValue;
      });
      state.getType = firstList;
    },
    [journal.fulfilled](state, { payload }) {
      // payload拿到成功返回的结果
      const { records } = payload.data;
      state.defaultData = records;
      state.loading = false;
    },
    [journal.rejected](state) {
      state.loading = false;
    },
    [journal.pending](state) {
      state.loading = true;
    },
    [detailsPanelData.fulfilled](state, { payload }) {
      // payload拿到成功返回的结果
      const { data } = payload;
      state.dataset = data;
      state.loading1 = false;
    },
    [detailsPanelData.rejected](state) {
      state.loading1 = false;
    },
    [detailsPanelData.pending](state) {
      state.loading1 = true;
    },
    [nodeDetailList.fulfilled](state, { payload }) {
      const { data } = payload;
      state.ReportTestDetail = data;
    }
  }
});

export const { loadDataEnd } = dictionaryis.actions;
export default dictionaryis.reducer;
