import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { changeName } from './userSlice';
import { get,post } from '@/api/request';

const AppliCationApi = (params) =>
  post(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/web/operate/dict/list/all?apipost_id=8a38e6',
    { ...params }
  ).then((res) => res);
const operateListApi = (params) =>
  post(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/journal?apipost_id=66ed6d',
    { ...params }
  ).then((res) => res);
export const Interface = (params) =>
  get(
    '/console-mock/mock/1ffad297-b9fe-46db-aef9-50315f462253/details?apipost_id=e455ef',
    { ...params }
  ).then((res) => res);
// 名称  解决异步请求接口使用 类似于promise 里面有fulfilled reject pending三种状态
export const journal = createAsyncThunk('public/journal', async (params) => {
  const res = await operateListApi(params);
  return res;
});
export const arrayToList = createAsyncThunk(
  'arrayToList/journal',
  async (params) => {
    const res = await AppliCationApi(params);
    return res;
  }
);
export const jqNextList = createAsyncThunk(
  'jqNextList/journal',
  async (params) => {
    const res = await AppliCationApi(params);
    return res;
  }
);
export const publiclog = createSlice({
  name: 'public',
  initialState: {
    toconfigure: {
      showJumper: true,
      sizeCanChange: false,
      showTotal: true,
      pageSizeChangeResetCurrent: false,
      hideOnSinglePage: true
    }, // Table配置项
    state: {
      border: true,
      borderCell: false,
      hover: true,
      stripe: false,
      showHeader: true,
      fixedHeader: false,
      no_data: false,
      size: 'default',
      pagePosition: 'br'
    }, // Table配置项
    defaultData: [], // 列表数据展示
    DropDown: [{ label: '', dictValue: '' }], // 操作选项
    AppliCation: [{ label: '', dictValue: '' }], // 应用选项
    loading: false, // 加载中
    total: 0
  },
  reducers: {
    loadDataEnd(state, { payload }) {
      state.list = payload;
      state.total = payload.length;
    }
  },

  // 可以关联其他reducer
  extraReducers: {
    // [changeName](state, { payload }) {
    //   console.log('extraReducers', state, payload);
    //   state.list.push(1);
    //   state.total += 1;
    // },
    [arrayToList.fulfilled](state, { payload }) {
      const { data } = payload;
      let firstList = [];
      firstList = data.map((item) => {
        let arr = {
          ...item,
          label: item.dictValue,
          value: Number(item.dictKey)
        };
        return arr;
      });
      firstList.unshift({ label: '全部', value: '' });
      state.DropDown = firstList;
    },
    [jqNextList.fulfilled](state, { payload }) {
      const { data } = payload;
      let firstList = [];
      firstList = data.map((item) => {
        let arr = {
          ...item,
          label: item.dictValue,
          value: Number(item.dictKey),
        };
        return arr;
      });
      firstList.unshift({ label: '全部', value: '' });
      state.AppliCation = firstList;
    },
    [journal.fulfilled](state, { payload }) {
      // payload拿到成功返回的结果
      const { records, total } = payload.data;
      state.defaultData = records;
      state.total = total;
      state.loading = false;
    },
    [journal.rejected](state) {
      state.loading = false;
    },
    [journal.pending](state) {
      state.loading = true;
    }
  }
});

export const { loadDataEnd } = publiclog.actions;
export default publiclog.reducer;
