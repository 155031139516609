import React from 'react';
import {
  Avatar,
  Dropdown,
  Menu,
  Divider,
  Message
} from '@arco-design/web-react';
import {
  IconUser,
  IconSettings,
  IconPoweroff,
  IconExperiment,
  IconDashboard,
  IconTag
} from '@arco-design/web-react/icon';
import { useSelector } from 'react-redux';
import logo2 from '@/assets/logo2.svg';
import { ReactSVG } from 'react-svg';
// import Settings from '../Settings';
import styles from './style/index.module.less';
import useStorage from '@/utils/useStorage';

// eslint-disable-next-line react/prop-types
function Navbar({ show }) {
  const { userInfo } = useSelector((state) => state.userInfoSlice);
  const { topMenuData, topMenuSelectedKeys } = useSelector(
    (state) => state.menuSlice
  );

  const [, setUserStatus] = useStorage('userStatus');
  const [role, setRole] = useStorage('userRole', 'admin');

  function logout() {
    setUserStatus('logout');
    window.location.href = '/login';
  }

  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    } else {
      Message.info(`You clicked ${key}`);
    }
  }

  if (!show) {
    return (
      <div className={styles['fixed-settings']}>
        {/* <Settings
          trigger={
            <Button icon={<IconSettings />} type='primary' size='large' />
          }
        /> */}
      </div>
    );
  }

  const handleChangeRole = () => {
    const newRole = role === 'admin' ? 'user' : 'admin';
    setRole(newRole);
  };

  const onClickMenuItem = () => {};

  const renderMenuItem = () => {
    return Array.isArray(topMenuData)
      ? topMenuData.map((item) => {
          return item.path === 'service' ? (
            <Menu.SubMenu
              key={item.path}
              title={
                <span className={styles['service-title']}>{item.name}</span>
              }
            >
              {item.children &&
                item.children.map((subItem) => (
                  <Menu.Item key={subItem.path}>{subItem.name}</Menu.Item>
                ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.path}>{item.name}</Menu.Item>
          );
        })
      : [];
  };

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.SubMenu
        key='role'
        title={
          <>
            <IconUser className={styles['dropdown-icon']} />
            <span className={styles['user-role']}>管理</span>
          </>
        }
      >
        <Menu.Item onClick={handleChangeRole} key='switch role'>
          <IconTag className={styles['dropdown-icon']} />
          切换角色
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key='setting'>
        <IconSettings className={styles['dropdown-icon']} />
        设置
      </Menu.Item>
      <Menu.SubMenu
        key='more'
        title={
          <div style={{ width: 80 }}>
            <IconExperiment className={styles['dropdown-icon']} />
            更多
          </div>
        }
      >
        <Menu.Item key='workplace'>
          <IconDashboard className={styles['dropdown-icon']} />
          工作区
        </Menu.Item>
      </Menu.SubMenu>

      <Divider style={{ margin: '4px 0' }} />
      <Menu.Item key='logout'>
        <IconPoweroff className={styles['dropdown-icon']} />
        退出
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <ReactSVG src={logo2} className={styles['logo-icon']} />
          <div className={styles['logo-name']}>公共服务平台</div>
        </div>
      </div>
      <Menu
        mode='horizontal'
        theme={false}
        accordion
        className={styles.menu}
        selectedKeys={topMenuSelectedKeys}
        onClickMenuItem={onClickMenuItem}
      >
        {renderMenuItem()}
      </Menu>
      <ul className={styles.right}>
        {userInfo && (
          <li>
            <Dropdown droplist={droplist} position='br'>
              <Avatar size={32} style={{ cursor: 'pointer' }}>
                <img alt='avatar' src={userInfo?.data?.avatar} />
              </Avatar>
            </Dropdown>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Navbar;
