import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '@/api/request';
import { requestUrl } from '@/config/index'

//  账户信息
export const getDetail = (params) =>
  post(
    `${requestUrl}/users/detail`,
    { ...params }
  ).then((res) => res.data);
export const getDetails = createAsyncThunk(
  'account/getDetail',
  async (params) => {
    const res = await getDetail(params);
    return res;
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    userInfo: {}
  },
  reducers: {},
  extraReducers: {
    [getDetails.fulfilled](state, { payload }) {
      state.userInfo = payload
    },
    [getDetails.rejected]() { },
    [getDetails.pending]() { },
  }
});

export default accountSlice.reducer;
