import { configureStore } from '@reduxjs/toolkit';
import userInfoSlice from './features/userInfoSlice';
import moiveSlice from './features/movieSlice';
import menuSlice from './features/menuSlice';
import logLogin from './features/logLogin';
import publiclog from './features/publiclog';
import securityForm from './features/securityForm';
import dictionaryis from './features/dictionaryis';

// 我的
import btnSlice from './mineStore/permissions';
import roleSlice from './mineStore/systemManagement/roleManagement';
import menuRoleSlice from './mineStore/systemManagement/muneManagement';
import dataDictionarySlice from './mineStore/systemManagement/dataDictionary';
import autonymUserSlice from './mineStore/userManagement/autonymUser';
import checkPendingUserSlice from './mineStore/userManagement/checkPendingUser';
import accountSlice from './mineStore/accountDetail';
import bannerSlice from './mineStore/contentManagement/bannerManagement';
import serverContentSlice from './mineStore/contentManagement/serveManagement';
import speciaContentSlice from './mineStore/contentManagement/specialManagement';
import platformSlice from './mineStore/contentManagement/platformRecommend';
import problemSlice from './mineStore/contentManagement/problemManagement';
import supplierSlice from './mineStore/supplierManagement';
import serveSlice from './mineStore/serveManagement';
import bodySlice from './mineStore/bodyManagement';
import logSlice from './mineStore/logPage';
import messageSlice from './mineStore/messageRegulate/messageInform';
import recordSlice from './mineStore/record';
import agreementSlice from './mineStore/agreement';
import homeSlice from './mineStore/home';
import dataBigscreenSlice from './mineStore/dataBigscreen';
import productSlice from './mineStore/productManagement';
import memberSlice from './mineStore/memberManagement';
import operationalSlice from './mineStore/operationalstatistics';
import overviewSlice from './mineStore/overview';
import operationRecordSlice from './mineStore/operationrecord';
import surveillanceCamerasSlice from './mineStore/surveillanceCameras';
import VendorblogServiceSlice from './mineStore/VendorblogService';
import  adminProductSlice from './mineStore/adminProductManage'
import linkSlice from './mineStore/editLink';

export default configureStore({
  reducer: {
    // 相当于combineReducer
    userInfoSlice,
    moiveSlice,
    menuSlice,
    logLogin,
    publiclog,
    securityForm,
    dictionaryis,

    btnSlice,
    roleSlice,
    menuRoleSlice,
    autonymUserSlice,
    checkPendingUserSlice,
    accountSlice,
    bannerSlice,
    serverContentSlice,
    problemSlice,
    speciaContentSlice,
    supplierSlice,
    serveSlice,
    bodySlice,
    dataDictionarySlice,
    logSlice,
    messageSlice,
    recordSlice,
    agreementSlice,
    homeSlice,
    dataBigscreenSlice,
    productSlice,
    memberSlice,
    operationalSlice,
    overviewSlice,
    operationRecordSlice,
    surveillanceCamerasSlice,
    VendorblogServiceSlice,
    adminProductSlice,
    platformSlice,
    linkSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // 取消费系列化数据检查 例如map类型
    })
});
