import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  菜单权限
export const getMenuList = (params) =>
  get(
    `${requestUrl}/menu/list`,
    { ...params }
  ).then((res) => res.data);

export const getMenuLists = createAsyncThunk(
  'role/getRoleList',
  async (params) => {
    const res = await getMenuList(params);
    return res;
  }
);

export const menuRoleSlice = createSlice({
  name: 'menuRole',
  initialState: {
    menuRoleList: [],
    menuRoleTotal:0
  },
  reducers: {},
  extraReducers: {
    [getMenuLists.fulfilled](state, { payload }) {
      state.menuRoleList = payload?.records ? payload?.records : []
      state.menuRoleTotal = payload?.total ? payload?.total : 0
    },
    [getMenuLists.rejected]() { },
    [getMenuLists.pending]() { },

  }
});

export default menuRoleSlice.reducer;
