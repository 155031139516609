import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
import { requestUrl } from '@/config/index';

// 查询待审批列表
export const getAuditGoodList = (params) =>
  get(`${requestUrl}/v2/web/goods/adminAuditGoodsList`, { ...params }).then(
    (res) => res.data
  );

export const getAuditGoodLists = createAsyncThunk(
  'audit/adminAuditGoodsList',
  async (params) => {
    const res = await getAuditGoodList(params);
    return res;
  }
);

// 查询已上架列表
export const getGoodList = (params) =>
  get(`${requestUrl}/v2/web/goods/adminGoodsList`, { ...params }).then(
    (res) => res.data
  );

export const getGoodLists = createAsyncThunk(
  'audit/adminGoodsList',
  async (params) => {
    const res = await getGoodList(params);
    return res;
  }
);

// 管理员审批
export const getAuditGoods = (params) =>
  post(`${requestUrl}/v2/web/goods/adminAuditGoods`, { ...params }).then(
    (res) => res
  );

//审批记录
export const getAuditRecord = (params) =>
  get(`${requestUrl}/v2/web/goods/auditRecord`, { ...params }).then(
    (res) => res.data
  );
export const getAuditRecords = createAsyncThunk(
  'audit/auditRecord',
  async (params) => {
    const res = await getAuditRecord(params);
    return res;
  }
);

// 冻结
export const getFrozen = (params) =>
  post(`${requestUrl}/v2/web/goods/frozen`, { ...params }).then((res) => res);

// 解冻
export const getThaw = (params) =>
  post(`${requestUrl}/v2/web/goods/thaw`, { ...params }).then(
    (res) => res
  );

// 批量冻结
export const getBatchFrozen = (params) =>
  post(`${requestUrl}/v2/web/goods/batchFrozen`, { ...params }).then((res) => res);

// 批量解冻
export const getBatchThaw = (params) =>
  post(`${requestUrl}/v2/web/goods/batchThaw`, { ...params }).then((res) => res);


// 添加溯源环节
export const getAddSource = (params) =>
  post(`${requestUrl}/v2/web/goods/addTraceLink`, { ...params }).then(
    (res) => res
  );

// 添加溯源环节
export const getEditSource = (params) =>
  post(`${requestUrl}/v2/web/goods/editTraceLink`, { ...params }).then(
    (res) => res
  );

// 配置溯源版本
export const getAddVersion = (params) =>
  post(`${requestUrl}/v2/web/goods/addTraceVersion`, { ...params }).then(
    (res) => res
  );

// 溯源版本下得信息
export const getVersionInfo = (params) =>
  get(`${requestUrl}/v2/web/goods/version/traceLinkInfo`, { ...params }).then(
    (res) => res.data
  );

export const getVersionInfos = createAsyncThunk(
  'version/trace',
  async (params) => {
    const res = await getVersionInfo(params);
    return res;
  }
);

// 版本禁用启用
export const getOpenTraceVersion = (params) =>
  get(`${requestUrl}/v2/web/goods/openTraceVersion`, { ...params }).then(
    (res) => res
  );

// 溯源环节详情
export const getTraceDetail = (params) =>
  get(`${requestUrl}/v2/web/goods/traceLinkDetail`, { ...params }).then(
    (res) => res
  );

// 删除溯源
export const getDelTrace = (params) =>
  get(`${requestUrl}/v2/web/goods/deleteTraceLink`, { ...params }).then(
    (res) => res
  );

// 生产登记记录
export const getProductRegister = (params) =>
  get(`${requestUrl}/v2/web/goods/registerRecord`, { ...params }).then(
    (res) => res.data
  );

export const getProductRegisters = createAsyncThunk(
  'register/record',
  async (params) => {
    const res = await getProductRegister(params);
    return res;
  }
);
// 登记清单 -小表格
export const getRegisterList = (params) =>
  get(`${requestUrl}/v2/web/goods/registerList`, { ...params }).then(
    (res) => res
  );

// 商品详情
export const getProductInfo = (params) =>
  get(`${requestUrl}/v2/web/goods/info`, { ...params }).then((res) => res.data);

// 服务列表
export const getCertificateList = (params) =>
  get(`${requestUrl}/v2/web/cowrie/server/list`, { ...params }).then(
    (res) => res
  );
// 认证
export const applyCertificate = (params) =>
  post(`${requestUrl}/v2/web/cowrie/approve`, { ...params }).then((res) => res);
export const adminProductSlice = createSlice({
  name: 'product',
  initialState: {
    auditGoodList: [],
    auditGoodTotal: 0,
    goodList: [],
    goodTotal: 0,
    auditRecordsList: [],
    auditRecordsTotal: 0,
    registerList: [],
    registerTotal: 0,
    versionList: [],
    versionData: []
  },
  reducers: {},
  extraReducers: {
    // 待审批商品列表
    [getAuditGoodLists.fulfilled](state, { payload }) {
      state.auditGoodList = payload?.records ? payload?.records : [];
      state.auditGoodTotal = payload?.total ? payload?.total : 0;
    },

    // 已上架列表
    [getGoodLists.fulfilled](state, { payload }) {
      state.goodList = payload?.records ? payload?.records : [];
      state.goodTotal = payload?.total ? payload?.total : 0;
    },

    // 审批记录
    [getAuditRecords.fulfilled](state, { payload }) {
      state.auditRecordsList = payload?.records ? payload?.records : [];
      state.auditRecordsTotal = payload?.total ? payload?.total : 0;
    },
   
    // 溯源版本详情
    [getVersionInfos.fulfilled](state, { payload }) {
      state.versionData = payload ? payload : [];
    },

    // 生产登记记录
    [getProductRegisters.fulfilled](state, { payload }) {
      state.registerList = payload?.records ? payload?.records : [];
      state.registerTotal = payload?.total ? payload?.total : 0;
    },
    [getProductRegisters.rejected]() {},
    [getProductRegisters.pending]() {}
  }
});

export default adminProductSlice.reducer;
