import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { extraRoutes } from '@/routes';
import { requestUrl } from '@/config/index'
const loadTopMenuApi = () =>
  get(
    `${requestUrl}/menu/getMenuTree`
  ).then((res) => res.data);
  
// 名称  解决异步请求接口使用 类似于promise 里面有fulfilled reject pending三种状态
export const fetchAllMenuData = createAsyncThunk(
  'menuData/loadData',
  async () => {
    const res = await loadTopMenuApi();
    return res;
  }
);

// const list = [
//   {
//     "id": "1",
//     "name": "内容管理",
//     "path": "contentManagement",
//     "ignore": false,
//     "children": [
//       {
//         "id": "11",
//         "name": "banner管理",
//         "path": "contentManagement/bannerManagement"
//       },
//       {
//         "id": "12",
//         "name": "推荐服务管理",
//         "path": "contentManagement/serveManagement"
//       },
//       {
//         "id": "13",
//         "name": "专题管理",
//         "path": "contentManagement/specialManagement"
//       }
//     ]
//   },
//   {
//     "id": "2",
//     "name": "供应商管理",
//     "path": "supplierManagement",
//     "ignore": false
//   },
//   {
//     "id": "3",
//     "name": "服务管理",
//     "path": "serveManagement",
//     "ignore": false,
//     "children": [
//       {
//         "id": "31",
//         "name": "审核记录",
//         "path": "serveManagement/record",
//         "ignore": true
//       },
//       {
//         "id": "32",
//         "name": "宝贝详情",
//         "path": "serveManagement/detail",
//         "ignore": true
//       }
//     ]
//   },
//   {
//     "id": "4",
//     "name": "系统管理",
//     "path": "systemManagement",
//     "ignore": false,
//     "children": [
//       {
//         "id": "41",
//         "name": "角色管理",
//         "path": "systemManagement/roleManagement"
//       },
//       {
//         "id": "42",
//         "name": "菜单管理",
//         "path": "systemManagement/menuManagement"
//       }
//     ]
//   },
//   {
//     "id": "5",
//     "name": "用户管理",
//     "path": "userManagement",
//     "ignore": false,
//     "children": [
//       {
//         "id": "51",
//         "name": "实名用户",
//         "path": "userManagement/autonymUser"
//       },
//       {
//         "id": "52",
//         "name": "待审核用户",
//         "path": "userManagement/checkPendingUser",
//         "children": [
//           {
//             "id": "54",
//             "name": "审核记录",
//             "path": "userManagement/checkPendingUser/record",
//             "ignore": true
//           }
//         ]
//       },
//     ]
//   },
//   {
//     "id": "6",
//     "name": "账户信息",
//     "path": "accountDetail",
//     "ignore": false
//   }
// ]

export const menuSlice = createSlice({
  name: 'menuData',
  initialState: {
    topMenuData: [],
    topMenuSelectedKeys: 'home',
    route: [],
    routeData: {
      defaultRoute: '',
      sideMenuData: []
    },
    breadcrumbMap: {}
  },
  reducers: {},

  // 可以关联其他reducer
  extraReducers: {
    [fetchAllMenuData.fulfilled](state, action) {
      // payload拿到成功返回的结果
      if(action.payload){
        state.routeData.sideMenuData = [...action.payload, ...extraRoutes];
      }
    },
    [fetchAllMenuData.rejected](_, err) {
      console.log('loadData.reject', err);
    },
    [fetchAllMenuData.pending]() {
      // console.log('pending', state);
    }
  }
});

export const { setRouteMap } = menuSlice.actions;
export default menuSlice.reducer;
