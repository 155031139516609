// 公用的静态配置放在这里 比如接口请求
// export const requestUrl = 'https://xinghuo.test03.qcw800.com'   //测试
// export const requestUrl = 'https://xinghuo.tjotcrc.cn/api'   //正式
// export const requestUrl = 'https://14d7e781.r2.cpolar.top'    //本地

// export const requestUrl = 'http://xinghuo-register-server.prod.idx.space/api'; //正式

export const requestUrl = process.env.baseUrl; //正式

// export const requestUrl = 'http://10.14.150.253:18000/api';
// export const requestUrl = 'http://10.14.148.103:80/api';
// export const requestUrl = 'http://10.14.151.145:80/api';
// export const requestUrl =
//   process.env.NODE_ENV === 'development' ? '/otc' : `${process.env.baseUrl}api`;
