import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  错误日志
export const getlogList = (params) =>
  get(
    `${requestUrl}/log/list`,
    { ...params }
  ).then((res) => res.data);
  
export const getlogLists = createAsyncThunk(
  'log/getlogLists',
  async (params) => {
    const res = await getlogList(params);
    return res;
  }
);

export const logSlice = createSlice({
  name: 'log',
  initialState: {
    logList:[],
    logTotal:0,
  },
  reducers: {},
  extraReducers: {
    [getlogLists.fulfilled](state, { payload }) {
      state.logList = payload?.records ? payload?.records : []
      state.logTotal = payload?.total ? payload?.total : 0
    },
    [getlogLists.rejected]() { },
    [getlogLists.pending]() { },
  }
});

export default logSlice.reducer;
