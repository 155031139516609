const loginInfo = {
  details: function (payload, typeData, appData) {
    const item = typeData.find(
      (item) => item.dictKey === payload.operationType
    );
    const item2 = appData.find((item) => item.dictKey === payload.appType);
    const data = [
      {
        key: 1,
        title: '用户信息',
        info: [
          { label: '用户名', value: payload.operationName },
          { label: '服务名称', value: payload.status },
          { label: '登录人IP', value: payload.ip },
          { label: '服务IP', value: payload.ip },
          { label: '应用类型', value: item2.dictValue },
          { label: '操作类型', value: item.dictValue },
          { label: '结果', value: payload.status === 0 ? '成功' : '失败' }
        ]
      },
      {
        key: 2,
        title: '请求相关',
        info: [
          { label: '请求参数', value: payload.params },
          { label: '用户代理', value: '' },
          // status 状态：0.成功；1.失败
          { label: '描述信息', value: '' }
        ]
      }
    ];
    return data;
  }
};

export default loginInfo;
