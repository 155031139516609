import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  待审核列表
export const getServerList = (params) =>
  get(
    `${requestUrl}/server/list`,
    { ...params }
  ).then((res) => res.data);
  
export const getServerLists = createAsyncThunk(
  'serve/getServerLists',
  async (params) => {
    const res = await getServerList(params);
    return res;
  }
);
 
// 审核记录
export const getServerInfoList = (params) =>
  get(
    `${requestUrl}/serverInfo/list`,
    { ...params }
  ).then((res) => res.data);
  
export const getServerInfoLists = createAsyncThunk(
  'serve/getServerInfoLists',
  async (params) => {
    const res = await getServerInfoList(params);
    return res;
  }
);
export const serveSlice = createSlice({
  name: 'serve',
  initialState: {
    serveList:[],
    serveTotal:0,
    serveInfoList:[],
    serveInfoTotal:0,
  },
  reducers: {},
  extraReducers: {
    [getServerLists.fulfilled](state, { payload }) {
      state.serveList = payload?.records ? payload?.records : []
      state.serveTotal = payload?.total ? payload?.total : 0
    },
    [getServerLists.rejected]() { },
    [getServerLists.pending]() { },
    [getServerInfoLists.fulfilled](state, { payload }) {
      state.serveInfoList = payload?.records ? payload?.records : []
      state.serveInfoTotal = payload?.total ? payload?.total : 0
    },
    [getServerInfoLists.rejected]() { },
    [getServerInfoLists.pending]() { },
  }
});

export default serveSlice.reducer;
