import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  关注记录
export const getSubscribeList = (params) =>
  get(
    `${requestUrl}/v2/web/interact/recordList`,  
    { ...params }
  ).then((res) => res.data);
  
export const getSubscribeLists = createAsyncThunk(
  'record/getSubscribeLists',
  async (params) => {
    const res = await getSubscribeList(params);
    return res;
  }
);
//  点赞记录
export const getLikeList = (params) =>
  get(
    `${requestUrl}/v2/web/interact/recordList`,   
    { ...params }
  ).then((res) => res.data);
  
export const getLikeLists = createAsyncThunk(
  'record/getLikeLists',
  async (params) => {
    const res = await getLikeList(params);
    return res;
  }
);
//  收藏记录
export const getCollectList = (params) =>
  get(
    `${requestUrl}/v2/web/interact/recordList`,  
    { ...params }
  ).then((res) => res.data);
  
export const getCollectLists = createAsyncThunk(  
  'record/getCollectLists',
  async (params) => {
    const res = await getCollectList(params);
    return res;
  }
);
//  转移记录
export const getTransferList = (params) =>
  get(
    `${requestUrl}/transfer/recordList`,   
    { ...params }
  ).then((res) => res.data);
  
export const getTransferLists = createAsyncThunk(  
  'record/getTransferLists',
  async (params) => {
    const res = await getTransferList(params);
    return res;
  }
);
//  浏览记录
export const getCatList = (params) =>
  get(
    `${requestUrl}/goods/cat/list`,  
    { ...params }
  ).then((res) => res.data);
  
export const getCatLists = createAsyncThunk( 
  'record/getCatLists',
  async (params) => {
    const res = await getCatList(params);
    return res;
  }
);
//  评论记录
export const getCommentList = (params) =>
  get(
    `${requestUrl}/v2/web/interact/recordList`,  
    { ...params }
  ).then((res) => res.data);
  
export const getCommentLists = createAsyncThunk( 
  'record/getCommentLists',
  async (params) => {
    const res = await getCommentList(params);
    return res;
  }
);
//  转发记录
export const getShareList = (params) =>
  get(
    `${requestUrl}/v2/web/interact/recordList`,  
    { ...params }
  ).then((res) => res.data);
  
export const getShareLists = createAsyncThunk( 
  'record/getShareLists',
  async (params) => {
    const res = await getShareList(params);
    return res;
  }
);

export const recordSlice = createSlice({
  name: 'record',
  initialState: {
    subscribeList:[],
    subscribeTotal:0,
    likeList:[],
    likeTotal:0,
    collectList:[],
    collectTotal:0,
    transferList:[],
    transferTotal:0,
    catList:[],
    catTotal:0,
    commentList:[],
    commentTotal:0,
    shareList:[],
    shareTotal:0,
  },
  reducers: {},
  extraReducers: {
    [getSubscribeLists.fulfilled](state, { payload }) {
      state.subscribeList = payload?.records ? payload?.records : []
      state.subscribeTotal = payload?.total ? payload?.total : 0
    },
    [getSubscribeLists.rejected]() { },
    [getSubscribeLists.pending]() { },
    [getLikeLists.fulfilled](state, { payload }) {
      state.likeList = payload?.records ? payload?.records : []
      state.likeTotal = payload?.total ? payload?.total : 0
    },
    [getLikeLists.rejected]() { },
    [getLikeLists.pending]() { },
    [getCollectLists.fulfilled](state, { payload }) {
      state.collectList = payload?.records ? payload?.records : []
      state.collectTotal = payload?.total ? payload?.total : 0
    },
    [getCollectLists.rejected]() { },
    [getCollectLists.pending]() { },
    [getTransferLists.fulfilled](state, { payload }) {
      state.transferList = payload?.records ? payload?.records : []
      state.transferTotal = payload?.total ? payload?.total : 0
    },
    [getTransferLists.rejected]() { },
    [getTransferLists.pending]() { },
    [getCatLists.fulfilled](state, { payload }) {
      state.catList = payload?.records ? payload?.records : []
      state.catTotal = payload?.total ? payload?.total : 0
    },
    [getCatLists.rejected]() { },
    [getCatLists.pending]() { },
    [getCommentLists.fulfilled](state, { payload }) {
      state.commentList = payload?.records ? payload?.records : []
      state.commentTotal = payload?.total ? payload?.total : 0
    },
    [getCommentLists.rejected]() { },
    [getCommentLists.pending]() { },
    [getShareLists.fulfilled](state, { payload }) {
      state.shareList = payload?.records ? payload?.records : []
      state.shareTotal = payload?.total ? payload?.total : 0
    },
    [getShareLists.rejected]() { },
    [getShareLists.pending]() { },
  }
});

export default recordSlice.reducer;
