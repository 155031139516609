import React from 'react';
import { Layout } from '@arco-design/web-react';
import cs from 'classnames';
import './style/index.less';

function Footer(props) {
  // eslint-disable-next-line react/prop-types
  const { className, ...restProps } = props;
  return (
    <Layout.Footer className={cs('footer', className)} {...restProps}>
      Copyright © 2016-2022 北京泰尔英福科技有限公司 京ICP备12003601号-6
    </Layout.Footer>
  );
}

export default Footer;
