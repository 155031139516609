import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index';
import { post } from '@/api/request';

//  列表
export const getCameraList = (params) =>
  get(`${requestUrl}/v2/web/camera/pcList`, { ...params }).then(
    (res) => res.data
  );

export const getCameraLists = createAsyncThunk(
  'camera/getCameraLists',
  async (params) => {
    const res = await getCameraList(params);
    return res;
  }
);
// 获取摄像头列表
export const pullCameraList = () =>
  get(`${requestUrl}/v2/web/camera/pcPullData`).then((res) => res);

// 启用禁用
export const changeStatusCamera = (params) =>
  get(`${requestUrl}/v2/web/camera/status`, { ...params }).then((res) => res);

// 编辑-关联信息
export const connectInfo = (params) =>
  get(`${requestUrl}/v2/web/camera/info`, { ...params }).then((res) => res);
// 编辑-关联保存
export const connectSave = (params) =>
  post(`${requestUrl}/v2/web/camera/edit`, { ...params }).then((res) => res);

// 关联商品-提交
export const connectReletion = (params) =>
  post(`${requestUrl}/v2/web/camera/correlation`, { ...params }).then(
    (res) => res
  );
// 关联商品-列表
export const getProductListApi = (params) =>
  get(`${requestUrl}/v2/web/goods/pcTopicList`, { ...params }).then(
    (res) => res.data
  );

export const getProductList = createAsyncThunk(
  'camera/productList',
  async (params) => {
    const res = await getProductListApi(params);
    return res;
  }
);

export const surveillanceCamerasSlice = createSlice({
  name: 'surveillanceCameras',
  initialState: {
    cameraList: [],
    cameraTotal: 0,
    productList: [],
    productListTotal: 0
  },
  reducers: {},
  extraReducers: {
    [getCameraLists.fulfilled](state, { payload }) {
      state.cameraList = payload?.records ? payload?.records : [];
      state.cameraTotal = payload?.total ? payload?.total : 0;
    },
    [getCameraLists.rejected]() {},
    [getCameraLists.pending]() {},
    [getProductList.fulfilled](state, { payload }) {
      state.productList = payload?.records ? payload?.records : [];
      state.productListTotal = payload?.total ? payload?.total : 0;
    }
  }
});

export default surveillanceCamerasSlice.reducer;
