import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
// import security from '../../pages/operator/log/config/security';
// http://localhost:3000/console-mock/mock/26a30523-bae2-4ed9-a1e8-696464c0099c/list?apipost_id=375094
const detailApi = (params) =>
  get(
    'http://localhost:3000/console-mock/mock/26a30523-bae2-4ed9-a1e8-696464c0099c/web/operate/login/security/detail?apipost_id=ba3d48',
    params
  ).then((res) => res.data);

export const updateApi = (params) =>
  post(
    'http://localhost:3000/console-mock/mock/26a30523-bae2-4ed9-a1e8-696464c0099c/web/operate/login/security/update?apipost_id=0735e5',
    params
  ).then((res) => res);

// const logDetailApi = (params) =>
//   get(
//     'http://localhost:3000/console-mock/mock/26a30523-bae2-4ed9-a1e8-696464c0099c/detail?apipost_id=2f0e50',
//     params
//   ).then((res) => res.data);

// 名称  解决异步请求接口使用 类似于promise 里面有fulfilled reject pending三种状态
export const fetchDetailData = createAsyncThunk(
  'securityForm/loadData',
  async (params) => {
    const res = await detailApi(params);
    return res;
  }
);

// export const fetchUpdateData = createAsyncThunk(
//   'securityFormUpdate/loadData',
//   async (params) => {
//     const res = await updateApi(params);
//     return res;
//   }
// );
// export const fetchLogDetail = createAsyncThunk(
//   'logDetail/loadData',
//   async (params) => {
//     const res = await logDetailApi(params);
//     return res;
//   }
// );

export const securityForm = createSlice({
  name: 'topMenu',
  initialState: {
    // formData: [],
    detailObj: []
  },
  reducers: {
    getDetails(state, { payload }) {
      state.detailObj = payload;
    }
    // updateFrom(state, { payload }) {
    //   state.formData = payload;
    // }
  },

  // 可以关联其他reducer
  extraReducers: {
    // [changeName](state, { payload }) {
    //   console.log('extraReducers', state, payload);
    //   state.list.push(1);
    //   state.totals += 1;
    // },
    [fetchDetailData.fulfilled](state, { payload }) {
      // payload拿到成功返回的结果
      state.detailObj = payload;
    },
    [fetchDetailData.rejected](state, err) {
      console.log('loadData.reject', err);
    },
    [fetchDetailData.pending]() {
      // console.log('pending', state);
    }
    // [fetchUpdateData.fulfilled](state, { payload }) {
    //   state.formData = payload;
    // }
  }
});

export const { getDetails, updateFrom } = securityForm.actions;
export default securityForm.reducer;
