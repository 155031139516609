import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { changeName } from './userSlice';
import { get } from '@/api/request';

const loadMoiveApi = () =>
  get('https://pcw-api.iqiyi.com/strategy/pcw/data/indexXpsdV4Data').then(
    (res) => res
  );
// 名称  解决异步请求接口使用 类似于promise 里面有fulfilled reject pending三种状态
export const loadData = createAsyncThunk('moive/loadData', async () => {
  const res = await loadMoiveApi();
  return res;
});

export const moiveSlice = createSlice({
  name: 'moive',
  initialState: {
    list: [],
    totals: 0
  },
  reducers: {
    loadDataEnd(state, { payload }) {
      state.list = payload;
      state.totals = payload.length;
    }
  },

  // 可以关联其他reducer
  extraReducers: {
    // [changeName](state, { payload }) {
    //   console.log('extraReducers', state, payload);
    //   state.list.push(1);
    //   state.totals += 1;
    // },
    [loadData.fulfilled](state, { payload }) {
      // payload拿到成功返回的结果
      state.list = payload.formatData.list;
    },
    [loadData.rejected](state, err) {
      console.log('loadData.reject', err);
    },
    [loadData.pending](state) {
      console.log('pending', state);
    }
  }
});

export const { loadDataEnd } = moiveSlice.actions;
export default moiveSlice.reducer;
