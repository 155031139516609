import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  常见问题条数
export const getQuesListCount = (params) =>
  get(
    `${requestUrl}/quest/listCount`,
    { ...params }
  ).then((res) => res.data);
export const getQuesListCounts = createAsyncThunk(
  'problem/getQuesListCounts',
  async (params) => {
    const res = await getQuesListCount(params);
    return res;
  }
);
// 常见问题列表
export const getQuesList= (params) =>
  get(
    `${requestUrl}/quest/list`,
    { ...params }
  ).then((res) => res.data);
export const getQuesLists = createAsyncThunk(
  'problem/getQuesLists',
  async (params) => {
    const res = await getQuesList(params);
    return res;
  }
);

export const problemSlice = createSlice({
  name: 'problem',
  initialState: {
    list:[],
    total:0,
    count:undefined
  },
  reducers: {},
  extraReducers: {
    [getQuesLists.fulfilled](state, { payload }) {
      state.list = payload?.records ? payload?.records : []
      state.total = payload?.total
    },
    [getQuesLists.rejected]() { },
    [getQuesLists.pending]() { },
    [getQuesListCounts.fulfilled](state, { payload }) {
      state.count = payload || 0
    },
    [getQuesListCounts.rejected]() { },
    [getQuesListCounts.pending]() { },
  }
});

export default problemSlice.reducer;
