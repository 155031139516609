import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  待审核用户
export const getUsersList = (params) =>
  get(
    `${requestUrl}/users/list`,
    { ...params }
  ).then((res) => res.data);

export const getUsersLists = createAsyncThunk(
  'checkPendingUser/checkPendingUserList',
  async (params) => {
    const res = await getUsersList(params);
    return res;
  }
);

export const getRecordList = (params) =>
  get(
    `${requestUrl}/attestation/list`,
    { ...params }
  ).then((res) => res.data);

export const getRecordLists = createAsyncThunk(
  'checkPendingUser/recordList',
  async (params) => {
    const res = await getRecordList(params);
    return res;
  }
);


export const checkPendingUserSlice = createSlice({
  name: 'checkPendingUser',
  initialState: {
    checkUserList: [],
    checkUserTotal: 0,
    recordList: [],
    recordTotal: 0,
  },
  reducers: {},
  extraReducers: {
    [getUsersLists.fulfilled](state, { payload }) {
      state.checkUserList = payload?.records ? payload?.records : []
      state.checkUserTotal = payload?.total ? payload?.total : 0
    },
    [getUsersLists.rejected]() { },
    [getUsersLists.pending]() { },
    [getRecordLists.fulfilled](state, { payload }) {
      state.recordList = payload?.records ? payload?.records : []
      state.recordTotal = payload?.total ? payload?.total : 0
    },
    [getRecordLists.rejected]() { },
    [getRecordLists.pending]() { },
  }
});

export default checkPendingUserSlice.reducer;
