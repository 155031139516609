import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index';

//  待审核列表
export const getServerList = (params) =>
  get(`${requestUrl}/v2/web/goods/server/list`, { ...params }).then(
    (res) => res.data
  );

export const getServerLists = createAsyncThunk(
  'blogService/getServerLists',
  async (params) => {
    const res = await getServerList(params);
    return res;
  }
);

// 审核记录
export const getServerInfoList = (params) =>
  get(`${requestUrl}/v2/web/goods/server/approve/record`, { ...params }).then(
    (res) => res.data
  );

export const getServerInfoLists = createAsyncThunk(
  'blogService/getServerInfoLists',
  async (params) => {
    const res = await getServerInfoList(params);
    return res;
  }
);

// 审核详情
export const getProductInfo = (params) =>
  get(`${requestUrl}/v2/web/goods/server/${params}`).then((res) => res.data);

export const serveSlice = createSlice({
  name: 'blogService',
  initialState: {
    serveList: [],
    serveTotal: 0,
    serveInfoList: [],
    serveInfoTotal: 0
  },
  reducers: {},
  extraReducers: {
    [getServerLists.fulfilled](state, { payload }) {
      state.serveList = payload?.records ? payload?.records : [];
      state.serveTotal = payload?.total ? payload?.total : 0;
    },
    [getServerLists.rejected]() {},
    [getServerLists.pending]() {},
    [getServerInfoLists.fulfilled](state, { payload }) {
      state.serveInfoList = payload?.records ? payload?.records : [];
      state.serveInfoTotal = payload?.total ? payload?.total : 0;
    },
    [getServerInfoLists.rejected]() {},
    [getServerInfoLists.pending]() {}
  }
});

export default serveSlice.reducer;
