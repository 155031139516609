import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'


//  消息条数
export const getMessageListCount = (params) =>
  get(
    `${requestUrl}/message/listCount`,
    { ...params }
  ).then((res) => res.data);
export const getMessageListCounts = createAsyncThunk(
  'problem/getMessageListCounts',
  async (params) => {
    const res = await getMessageListCount(params);
    return res;
  }
);
// 消息列表
export const getMessageList= (params) =>
  get(
    `${requestUrl}/message/list`,
    { ...params }
  ).then((res) => res.data);
export const getMessageLists = createAsyncThunk(  
  'message/getMessageLists',
  async (params) => {
    const res = await getMessageList(params);
    return res;
  }
);

export const messageSlice = createSlice({
  name: 'message',
  initialState: {
    list:[],
    total:0,
    count:undefined
  },
  reducers: {},
  extraReducers: {
    [getMessageLists.fulfilled](state, { payload }) {
      state.list = payload?.records ? payload?.records : []
      state.total = payload?.total
    },
    [getMessageLists.rejected]() { },
    [getMessageLists.pending]() { },
    [getMessageListCounts.fulfilled](state, { payload }) {
      state.count = payload || 0
    },
    [getMessageListCounts.rejected]() { },
    [getMessageListCounts.pending]() { },
  }
});

export default messageSlice.reducer;
