import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

export const getLatestList = (params) =>
  get(
    `${requestUrl}/home/latestList`,
    { ...params }
  ).then((res) => res.data);

export const getLatestLists = createAsyncThunk(
  'dataBigscreen/getLatestLists',
  async (params) => {
    const res = await getLatestList(params);
    return res;
  }
);

// 宝贝运营数据统计
export const getShieldTjBodyList = (params) =>
  get(
    `${requestUrl}/home/shieldTjList`,
    { ...params }
  ).then((res) => res.data);

export const getShieldTjBodyLists = createAsyncThunk(
  'dataBigscreen/getShieldTjBodyLists',
  async (params) => {
    const res = await getShieldTjBodyList(params);
    return res;
  }
);

// 用户注册数据统计
export const getShieldTjUserList = (params) =>
  get(
    `${requestUrl}/home/shieldTjList`,
    { ...params }
  ).then((res) => res.data);

export const getShieldTjUserLists = createAsyncThunk(
  'dataBigscreen/getShieldTjUserLists',
  async (params) => {
    const res = await getShieldTjUserList(params);
    return res;
  }
);

// 地图数据
export const getMapTjList = (params) =>
  get(
    `${requestUrl}/home/mapTjList`,
    { ...params }
  ).then((res) => res.data);

export const getMapTjLists = createAsyncThunk(
  'dataBigscreen/getMapTjLists',
  async (params) => {
    const res = await getMapTjList(params);
    return res;
  }
);




export const dataBigscreenSlice = createSlice({
  name: 'dataBigscreen',
  initialState: {
    dataBigscreenData: null,
    bodyData: null,
    userData: null,
    mapData:null
  },
  reducers: {},
  extraReducers: {
    [getLatestLists.fulfilled](state, { payload }) {
      state.dataBigscreenData = payload.data || null
    },
    [getShieldTjBodyLists.fulfilled](state, { payload }) {
      state.bodyData = payload.data || null
    },
    [getShieldTjUserLists.fulfilled](state, { payload }) {
      state.userData = payload.data || null
    },
    [getMapTjLists.fulfilled](state, { payload }) {
      state.mapData = payload || null
    }
  }
});

export default dataBigscreenSlice.reducer;
