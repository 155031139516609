import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  账户信息
export const getSupplierList = (params) =>
  get(
    `${requestUrl}/supplier/list`,
    { ...params }
  ).then((res) => res.data);
export const getSupplierLists = createAsyncThunk(
  'supplier/getSupplierLists',
  async (params) => {
    const res = await getSupplierList(params);
    return res;
  }
);


export const supplierSlice = createSlice({
  name: 'supplier',
  initialState: {
    supplierList: [],
    supplierTotal: []
  },
  reducers: {},
  extraReducers: {
    [getSupplierLists.fulfilled](state, { payload }) {
      state.supplierList = payload?.records ? payload?.records : []
      state.supplierTotal = payload?.total ? payload?.total : 0
    },
    [getSupplierLists.rejected]() { },
    [getSupplierLists.pending]() { },
  }
});

export default supplierSlice.reducer;
