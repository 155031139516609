import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
import { requestUrl } from '@/config/index';
// import Qs from 'qs';
//  商品运营统计
export const getlogList = (params) =>
  get(`${requestUrl}/v2/web/goods/operationalStatisticsList`, {
    ...params
  }).then((res) => res.data);

// 批量导出商品统计
export const exportExcel = (params) =>
  post(`${requestUrl}/v2/web/goods/exportExcel`, params, {
    responseType: 'blob'
  }).then((res) => res);
//  互动记录
export const operList = (params) =>
  get(`${requestUrl}/v2/web/interact/list`, { ...params }).then(
    (res) => res.data
  );

// 登记清单 -小表格
export const getRegisterList = (params) =>
  get(`${requestUrl}/v2/web/goods/operateRegisterList`, { ...params }).then(
    (res) => res
  );
// 批量导出BID码
export const batchExportBid = (params) =>
  post(`${requestUrl}/v2/web/cowrie/exportBid`, [...params], {
    responseType: 'blob'
  }).then((res) => res);
export const getlogLists = createAsyncThunk(
  'operational/getlogLists',
  async (params) => {
    const res = await getlogList(params);
    return res;
  }
);
// 互动记录
export const fetchoperList = createAsyncThunk(
  'fetch/fetchoperList',
  async (params) => {
    const res = await operList(params);
    return res;
  }
);

export const operationalSlice = createSlice({
  name: 'operational',
  initialState: {
    logList: [],
    loadings: null,
    logTotal: 0,
    // 互动记录
    RecordingList: [],
    pagination: {
      total: 0,
      pageSize: 10,
      current: 1,
      showJumper: true,
      showTotal: true,
      hideOnSinglePage: true,
      pageSizeChangeResetCurrent: true,
      style: { margin: '16px 20px 0px' }
    },
    loading: null
  },
  reducers: {
    getLoading(state, { payload }) {
      state.loading = payload;
    },
    getPagination(state, { payload }) {
      state.pagination = payload;
    }
  },
  extraReducers: {
    [getlogLists.fulfilled](state, { payload }) {
      state.logList = payload?.records ? payload?.records : [];
      state.logTotal = payload?.total ? payload?.total : 0;
      state.loadings = false;
    },
    [getlogLists.rejected](state) {
      state.loadings = false;
    },
    [getlogLists.pending](state) {
      state.loadings = true;
    },
    // 互动记录
    [fetchoperList.fulfilled](state, { payload }) {
      state.RecordingList = payload?.records ?? [];
      state.pagination.total = payload?.total ? payload?.total : 0;
      state.loading = false;
    },
    [fetchoperList.rejected](state) {
      state.loading = false;
    },
    [fetchoperList.pending](state) {
      state.loading = true;
    }
  }
});

export const { getDictType, getPagination, getLoading } =
  operationalSlice.actions;
export default operationalSlice.reducer;
