import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get ,post} from '@/api/request';
import { requestUrl } from '@/config/index';

//  第三方推荐
export const getPcList = (params) =>
  get(`${requestUrl}/v2/web/platform-recommend/pcList`, { ...params }).then((res) => res.data);

export const getPcLists = createAsyncThunk(
  'platformRecommend/pcList',
  async (params) => {
    const res = await getPcList(params);
    return res;
  }
);

// 编辑接口
export const getEdit = (params) =>
  post(`${requestUrl}/v2/web/platform-recommend/edit`, { ...params }).then((res) => res);

  // 新增
export const getAdd = (params) =>
  post(`${requestUrl}/v2/web/platform-recommend/add`, { ...params }).then((res) => res);

// 删除
export const getRemove = (params) =>
  get(`${requestUrl}/v2/web/platform-recommend/remove`, { ...params }).then((res) => res);


export const platformSlice = createSlice({
  name: 'platformSlice',
  initialState: {
    pcList: [],
    pcTotal: 0,

  },
  reducers: {},
  extraReducers: {
    [getPcLists.fulfilled](state, { payload }) {
      state.pcList = payload?.records ? payload?.records : [];
      state.pcTotal = payload?.total ? payload.total : 0;
    },
    
  }
});

export default platformSlice.reducer;
