import React, { useEffect } from 'react';
import LoginForm from './form';
import './style/index.less';

function Login() {
  useEffect(() => {
    document.body.setAttribute('arco-theme', 'light');
  }, []);

  return (
    <div className='login-container'>
      <div className='inner'>
        <div className='content'>
          <div className='form'>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}
Login.displayName = 'LoginPage';

export default Login;
