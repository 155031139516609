import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
// import lazyload from './utils/lazyload';

export const IRoute =
  AuthParams &
  {
    name: '',
    key: '',
    // 当前页是否展示面包屑
    breadcrumb: false,
    children: [],
    // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
    ignore: true
  };

// 设置额外跳转路由
export const extraRoutes = [
  // {
  //   name: '审核记录',
  //   parentKey: 'serveManagement/record', // 追溯面包屑使用
  //   path: 'serveManagement/record',
  //   ignore: false, // 是否在menu中展示
  //   component: lazyload(() => import('./pages/serveManagement/record/index'))
  // }
  // {
  //   name: '宝贝详情',
  //   parentKey: 'serveManagement/detail', // 追溯面包屑使用
  //   path: 'serveManagement/detail',
  //   ignore: true, // 是否在menu中展示
  //   component: lazyload(() => import('./pages/serveManagement/detail/index'))
  // },
  // {
  //   name: '审核记录',
  //   parentKey: 'userManagement/checkPendingUser/record', // 追溯面包屑使用
  //   path: 'userManagement/checkPendingUser/record',
  //   ignore: true, // 是否在menu中展示
  //   component: lazyload(() => import('./pages/userManagement/checkPendingUser/record/index'))
  // },
  // {
  //   name: '商品运营统计',
  //   parentKey: 'operationalstatistics', // 追溯面包屑使用
  //   path: 'operationalstatistics',
  //   ignore: false, // 是否在menu中展示
  //   component: lazyload(() => import('./pages/operationalstatistics/index'))
  // }
  // {
  //   name: '成员管理',
  //   parentKey: 'membermanagement', // 追溯面包屑使用
  //   path: 'membermanagement',
  //   ignore: false, // 是否在menu中展示
  //   component: lazyload(() => import('./pages/membermanagement/index'))
  // }
];
// {
//     name: '操作记录',
//     parentKey: 'operationrecord', // 追溯面包屑使用
//     path: 'operationrecord',
//     ignore: false, // 是否在menu中展示
//     component: lazyload(() => import('./pages/operationrecord/index'))
//   {
//      name: '申请认证记录',
//     parentKey: 'operationrecord/certificationrecord', // 追溯面包屑使用
//     path: 'operationrecord/certificationrecord',
//     ignore: false, // 是否在menu中展示
//     component: lazyload(() => import('./pages/operationrecord/certificationrecord/index'))

//   }
//   {
//      name: '宝贝转移记录',
//     parentKey: 'operationrecord/babytransfer', // 追溯面包屑使用
//     path: 'operationrecord/babytransfer',
//     ignore: false, // 是否在menu中展示
//     component: lazyload(() => import('./pages/operationrecord/babytransfer/index'))

//   }
//    {
//      name: '宝贝销毁记录',
//     parentKey: 'operationrecord/treasuredestruction', // 追溯面包屑使用
//     path: 'operationrecord/treasuredestruction',
//     ignore: false, // 是否在menu中展示
//     component: lazyload(() => import('./pages/operationrecord/treasuredestruction/index'))

//   }
//    {
//      name: '向上追溯记录',
//     parentKey: 'operationrecord/upwardtracing', // 追溯面包屑使用
//     path: 'operationrecord/upwardtracing',
//     ignore: false, // 是否在menu中展示
//     component: lazyload(() => import('./pages/operationrecord/upwardtracing/index'))

//   }

// }
// {
//   name: '成员管理',
//     parentKey: 'membermanagement', // 追溯面包屑使用
//       path: 'membermanagement',
//         ignore: false, // 是否在menu中展示
//           component: lazyload(() => import('./pages/membermanagement/index'))
// }
// {
//     name: '商品运营统计',
//     parentKey: 'operationalstatistics', // 追溯面包屑使用
//     path: 'operationalstatistics',
//     ignore: false, // 是否在menu中展示
//     component: lazyload(() => import('./pages/operationalstatistics/index'))
//   }

export const getName = (path = '', routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

export const generatePermission = (role = '') => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  extraRoutes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

// 获取父级菜单和侧边栏菜单

const useRoute = (userPermission, routeData) => {
  const filterRoute = (routes = IRoute, arr = []) => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(extraRoutes);

  useEffect(() => {
    const newRoutes = filterRoute(routeData);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission), JSON.stringify(routeData)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
