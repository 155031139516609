import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
import { requestUrl } from '@/config/index';

// 查询列表
export const getProductList = (params) =>
  get(`${requestUrl}/v2/web/goods/pcGoodsList`, { ...params }).then(
    (res) => res.data
  );

export const getProductLists = createAsyncThunk(
  'product/productList',
  async (params) => {
    const res = await getProductList(params);
    return res;
  }
);
// 商品上架
export const getListing = (params) =>
  get(`${requestUrl}/v2/web/goods/onShelf`, { ...params }).then((res) => res);

// 商品下架
export const getDelist = (params) =>
  get(`${requestUrl}/v2/web/goods/offShelf`, { ...params }).then((res) => res);

// 批量上架
export const getBatchShelf = (data) =>
  post(`${requestUrl}/v2/web/goods/onShelfBatch?ids=${data.ids}`).then(
    (res) => res
  );

// 批量下架
export const getBatchDelist = (data) =>
  post(`${requestUrl}/v2/web/goods/offShelfBatch?ids=${data.ids}`).then(
    (res) => res
  );

// 溯源版本详情
export const getVersionList = (params) =>
  get(`${requestUrl}/v2/web/goods/traceVersionList`, { ...params }).then(
    (res) => res.data
  );

export const getVersionLists = createAsyncThunk(
  'trace/version',
  async (params) => {
    const res = await getVersionList(params);
    return res;
  }
);

// 获取负责人
export const getPeople = (params) =>
  get(`${requestUrl}/v2/web/goods/people`, { ...params }).then((res) => res);

// 获取地方
export const getRegion = (params) =>
  get(`${requestUrl}/v2/web/goods/selectRegion`, { ...params }).then(
    (res) => res
  );

// 添加溯源环节
export const getAddSource = (params) =>
  post(`${requestUrl}/v2/web/goods/addTraceLink`, { ...params }).then(
    (res) => res
  );

// 添加溯源环节
export const getEditSource = (params) =>
  post(`${requestUrl}/v2/web/goods/editTraceLink`, { ...params }).then(
    (res) => res
  );

// 配置溯源版本
export const getAddVersion = (params) =>
  post(`${requestUrl}/v2/web/goods/addTraceVersion`, { ...params }).then(
    (res) => res
  );

// 溯源版本下得信息
export const getVersionInfo = (params) =>
  get(`${requestUrl}/v2/web/goods/version/traceLinkInfo`, { ...params }).then(
    (res) => res.data
  );

export const getVersionInfos = createAsyncThunk(
  'version/trace',
  async (params) => {
    const res = await getVersionInfo(params);
    return res;
  }
);

// 版本禁用启用
export const getOpenTraceVersion = (params) =>
  get(`${requestUrl}/v2/web/goods/openTraceVersion`, { ...params }).then(
    (res) => res
  );

// 溯源环节详情
export const getTraceDetail = (params) =>
  get(`${requestUrl}/v2/web/goods/traceLinkDetail`, { ...params }).then(
    (res) => res
  );

// 删除溯源
export const getDelTrace = (params) =>
  get(`${requestUrl}/v2/web/goods/deleteTraceLink`, { ...params }).then(
    (res) => res
  );

// 生产登记记录
export const getProductRegister = (params) =>
  get(`${requestUrl}/v2/web/goods/registerRecord`, { ...params }).then(
    (res) => res.data
  );

export const getProductRegisters = createAsyncThunk(
  'register/record',
  async (params) => {
    const res = await getProductRegister(params);
    return res;
  }
);
// 登记清单 -小表格
export const getRegisterList = (params) =>
  get(`${requestUrl}/v2/web/goods/registerList`, { ...params }).then(
    (res) => res
  );

// 商品详情
export const getProductInfo = (params) =>
  get(`${requestUrl}/v2/web/goods/info`, { ...params }).then((res) => res.data);

// 服务列表
export const getCertificateList = (params) =>
  get(`${requestUrl}/v2/web/cowrie/server/list`, { ...params }).then(
    (res) => res
  );
// 认证
export const applyCertificate = (params) =>
  post(`${requestUrl}/v2/web/goods/applyServerCert`, { ...params }).then(
    (res) => res
  );

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    productList: [],
    productTotal: 0,
    registerList: [],
    registerTotal: 0,
    versionList: [],
    versionData: []
  },
  reducers: {},
  extraReducers: {
    // 商品列表
    [getProductLists.fulfilled](state, { payload }) {
      state.productList = payload?.records ? payload?.records : [];
      state.productTotal = payload?.total ? payload?.total : 0;
    },
    [getProductLists.rejected]() {},
    [getProductLists.pending]() {},
    // 溯源版本
    [getVersionLists.fulfilled](state, { payload }) {
      state.versionList = payload ? payload : [];
    },
    // 溯源版本详情
    [getVersionInfos.fulfilled](state, { payload }) {
      state.versionData = payload ? payload : [];
    },

    // 生产登记记录
    [getProductRegisters.fulfilled](state, { payload }) {
      state.registerList = payload?.records ? payload?.records : [];
      state.registerTotal = payload?.total ? payload?.total : 0;
    },
    [getProductRegisters.rejected]() {},
    [getProductRegisters.pending]() {}
  }
});

export default productSlice.reducer;
