import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post, get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  角色权限
export const postAllPowerFtn = (params) =>
  post(
    `${requestUrl}/role/getAllPower`,
    { ...params }
  ).then((res) => res.data);
export const getRoleList = (params) =>
  get(
    `${requestUrl}/role/list`,
    { ...params }
  ).then((res) => res.data);

export const postAllPowerFtns = createAsyncThunk(
  'role/postAllPowerFtn',
  async (params) => {
    const res = await postAllPowerFtn(params);
    return res;
  }
);
export const getRoleLists = createAsyncThunk(
  'role/getRoleList',
  async (params) => {
    const res = await getRoleList(params);
    return res;
  }
);

export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    menuList: [],
    roleList: [],
    roleTotal: 0
  },
  reducers: {},
  extraReducers: {
    [postAllPowerFtns.fulfilled](state, { payload }) {
      state.menuList = payload && payload.map(item => {
        return {
          title: item.name,
          key: item.id,
          children: item.children && item.children.map(val => {
            return {
              title: val.name,
              key: val.id,
              children: val.children && val.children.map(v => {
                return {
                  title: v.name,
                  key: v.id,
                }
              })
            }
          })
        }
      })
    },
    [postAllPowerFtns.rejected]() { },
    [postAllPowerFtns.pending]() { },
    [getRoleLists.fulfilled](state, { payload }) {
      state.roleList = payload?.records ? payload?.records : []
      state.roleTotal = payload?.total ? payload?.total : 0
    },
    [getRoleLists.rejected]() { },
    [getRoleLists.pending]() { },

  }
});

export default roleSlice.reducer;
