import axios from 'axios';
import { Notification } from '@arco-design/web-react';
const token = localStorage.getItem('token');

// 创建 axios 实例
const service = axios.create({
  //   baseURL: '/api', // api base_url
  withCredentials: true,
  timeout: 300000, // 超时时间：6分钟 注意和nginx配置保持一致
  maxBodyLength: 31457280, // 请求体最大长度 单位B，上限30MB 注意和nginx配置保持一致
  maxContentLength: 5242880 // 响应的最大长度，单位 B ，5MB，针对文件或者表格数据大量返回 注意和nginx配置保持一致
});

const err = (error) => {
  if (error.response) {
    const data = error.response.data;
    if (error.response.code === 403) {
      // 登录失效
      window.location.href = '/admin/login';
      localStorage.setItem('userStatus', '');
      localStorage.setItem('token', '');
    } else if (error.response.status === 402) {
      // 缺少权限
      Notification.error({
        message: '您没有权限查看当前信息',
        description: '请联系管理员获取数据权限',
        duration: null
      });
    } else if (error.response.status === 403) {
      Notification.error({
        message: 'Forbidden',
        description: data.message,
        duration: null
      });
    } else if (error.response.status === 504) {
      // 请求超时
      Notification.error({
        message: '提示',
        description: '请求时间较长，请稍后查看',
        duration: null
      });
    } else if (error.response.status === 500) {
      // 服务端报错
      Notification.error({
        message: '提示',
        description: '服务异常',
        duration: null
      });
    }
  } else {
    // 请求超时
    Notification.error({
      message: '提示',
      description: '请求时间较长，请稍后查看',
      duration: null
    });
  }
  return Promise.reject(error);
};

// 请求拦截，设置token
service.interceptors.request.use((config) => {
  // 替换其他参数
  // get请求添加content-type
  if (config.method === 'get') {
    config.data = true;
  }
  config.headers['Authorization'] = `Bearer ${token}` || '';
  return config;
}, err);

// 处理响应
service.interceptors.response.use((response) => {
  return response;
}, err);

export function get(url, params = {}, options) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
        ...options
      })
      .then((response) => {
        if (response.data.code === 403) {
          Notification.error({
            title: '提示',
            content: '会话超时，请重新登录',
          });
          setTimeout(()=>{
            localStorage.setItem('userStatus', '');
            localStorage.setItem('token', '');
            window.location.href = '/';
          },1500)
          return
        }
        // 特殊设置
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function post(url, data, options) {
  return new Promise((resolve, reject) => {
    service.post(url, data, options).then(
      (response) => {
        if (response.data.code === 403) {
          Notification.error({
            title: '提示',
            content: '会话超时，请重新登录',
          });
          setTimeout(()=>{
            localStorage.setItem('userStatus', '');
            localStorage.setItem('token', '');
            window.location.href = '/';
          },1500)
          return
        }
        //关闭进度条
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    service.patch(url, data).then(
      (response) => {
        if (response.data.code === 403) {
          Notification.error({
            title: '提示',
            content: '会话超时，请重新登录',
          });
          setTimeout(()=>{
            localStorage.setItem('userStatus', '');
            localStorage.setItem('token', '');
            window.location.href = '/';
          },1500)
          return
        }
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    service.put(url, data).then(
      (response) => {
        if (response.data.code === 403) {
          Notification.error({
            title: '提示',
            content: '会话超时，请重新登录',
          });
          setTimeout(()=>{
            localStorage.setItem('userStatus', '');
            localStorage.setItem('token', '');
            window.location.href = '/';
          },1500)
          return
        }
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}


export function deletes(url, data = {}) {
  return new Promise((resolve, reject) => {
    service.delete(url, data).then(
      (response) => {
        if (response.data.code === 403) {
          Notification.error({
            title: '提示',
            content: '会话超时，请重新登录',
          });
          setTimeout(()=>{
            localStorage.setItem('userStatus', '');
            localStorage.setItem('token', '');
            window.location.href = '/';
          },1500)
          return
        }
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}


// export { requests as axios };
