import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index';
import { post } from '@/api/request';

//  宝贝列表
export const getBodyList = (params) =>
  get(`${requestUrl}/v2/web/cowrie/list`, { ...params }).then(
    (res) => res.data
  );

export const getBodyLists = createAsyncThunk(
  'body/getBodyLists',
  async (params) => {
    const res = await getBodyList(params);
    return res;
  }
);

// 已发布列表
export const getReleaseList = (params) =>
  get(`${requestUrl}/drafts/checked`, { ...params }).then((res) => res.data);

export const getReleaseLists = createAsyncThunk(
  'body/getReleaseLists',
  async (params) => {
    const res = await getReleaseList(params);
    return res;
  }
);
// 首页banner图
export const getReleaseList2 = (params) =>
  get(`${requestUrl}/v2/web/goods/bannerGoodsList`, { ...params }).then((res) => res.data);

export const getReleaseLists2 = createAsyncThunk(
  'body/getReleaseList2',
  async (params) => {
    const res = await getReleaseList2(params);
    return res;
  }
);
// 审核记录
export const getCertList = (params) =>
  get(`${requestUrl}/cert/list`, { ...params }).then((res) => res.data);

export const getCertLists = createAsyncThunk(
  'body/getCertLists',
  async (params) => {
    const res = await getCertList(params);
    return res;
  }
);

// 互动记录
export const getInteractList = (params) =>
  get(`${requestUrl}/interact/list`, { ...params }).then((res) => res.data);

export const getInteractLists = createAsyncThunk(
  'body/getInteractLists',
  async (params) => {
    const res = await getInteractList(params);
    return res;
  }
);

// 冻结、解冻、销毁
export const changeStatusBaby = (params) =>
  post(`${requestUrl}/v2/web/cowrie/statusChange`, { ...params }).then(
    (res) => res
  );
// 导出追溯模板
export const exportTraceTemplate = () =>
  get(`${requestUrl}/dict/getByKey?dictKey=sourceModel`).then((res) => res);
// 导入追溯信息
export const importTraceInfo = (params) =>
  post(`${requestUrl}/v2/web/cowrie/importSource`, params).then((res) => res);
// 保存追溯模板
export const saveTraceInfo = (params) => {
  return post(`${requestUrl}/v2/web/cowrie/saveSource`, { ...params }).then(
    (res) => res
  );
};
// 批量导出BID码
export const batchExportBid = (params) =>
  post(`${requestUrl}/v2/web/cowrie/exportBid`, [...params], {
    responseType: 'blob'
  }).then((res) => res);

// 认证
export const applyCertificate = (params) =>
  post(`${requestUrl}/v2/web/cowrie/approve`, { ...params }).then((res) => res);

// 认证服务商列表
export const getcertificateListApi = (params) =>
  get(`${requestUrl}/v2/web/cowrie/server/list`, { ...params }).then(
    (res) => res.data
  );

export const getcertificateList = createAsyncThunk(
  'body/certificateList',
  async (params) => {
    const res = await getcertificateListApi(params);
    return res;
  }
);
// 互关好友列表
export const getConnectFriendsListApi = (params) =>
  get(`${requestUrl}/v2/web/cowrie/subscribe/pcList`, { ...params }).then(
    (res) => res.data
  );

export const getConnectFriendsList = createAsyncThunk(
  'body/getConnectFriends',
  async (params) => {
    const res = await getConnectFriendsListApi(params);
    return res;
  }
);
// 批量转移
export const batchTransfer = (params) =>
  post(`${requestUrl}/v2/web/cowrie/batchTransfer`, { ...params }).then(
    (res) => res
  );

export const bodySlice = createSlice({
  name: 'body',
  initialState: {
    bodyList: [],
    bodyTotal: 0,
    releaseList: [],
    releaseTotal: 0,
    releaseList2: [],
    releaseTotal2: 0,
    bodyInfoList: [],
    bodyInfoTotal: 0,
    interactList: [],
    interactTotal: 0,
    certificateList: [],
    certificateListTotal: 0,
    connectFriendsList: [],
    connectFriendsListTotal: 0
  },
  reducers: {},
  extraReducers: {
    [getBodyLists.fulfilled](state, { payload }) {
      state.bodyList = payload?.records ? payload?.records : [];
      state.bodyTotal = payload?.total ? payload?.total : 0;
    },
    [getBodyLists.rejected]() {},
    [getBodyLists.pending]() {},
    [getcertificateList.fulfilled](state, { payload }) {
      state.certificateList = payload?.records ? payload?.records : [];
      state.certificateListTotal = payload?.total ? payload?.total : 0;
    },
    [getConnectFriendsList.fulfilled](state, { payload }) {
      state.connectFriendsList = payload?.records ? payload?.records : [];
      state.connectFriendsListTotal = payload?.total ? payload?.total : 0;
    },
    [getReleaseLists.fulfilled](state, { payload }) {
      state.releaseList = payload?.records ? payload?.records : [];
      state.releaseTotal = payload?.total ? payload?.total : 0;
    },
    [getReleaseLists.rejected]() {},
    [getReleaseLists.pending]() {},
    [getReleaseLists2.fulfilled](state, { payload }) {
      state.releaseList2 = payload ? payload : [];
      state.releaseTotal2 = payload?.length ? payload?.length : 0;
    },
    
    [getCertLists.fulfilled](state, { payload }) {
      state.bodyInfoList = payload?.records ? payload?.records : [];
      state.bodyInfoTotal = payload?.total ? payload?.total : 0;
    },
    [getCertLists.rejected]() {},
    [getCertLists.pending]() {},
    [getInteractLists.fulfilled](state, { payload }) {
      state.interactList = payload?.records ? payload?.records : [];
      state.interactTotal = payload?.total ? payload?.total : 0;
    },
    [getInteractLists.rejected]() {},
    [getInteractLists.pending]() {}
  }
});

export default bodySlice.reducer;
