import React, { useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Message
} from '@arco-design/web-react';
import { IconUser, IconMobile, IconLock } from '@arco-design/web-react/icon';
import { requestUrl } from '@/config/index'
import { post } from '@/api/request';
export default function LoginForm() {
  const formRef = useRef();
  const formPsw = useRef();
  const [form] = Form.useForm();
  const [psw, setPsw] = useState(false)
  const [loading, setLoading] = useState(false);
  const phoneTs = /^1[2|3|4|5|6|7|8|9][0-9]{9}$/; //手机号正则
  const emailTs = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  const [params, setParams] = useState({})
  const [codeStatus, setCodeStatus] = useState(false)
  const codeStatus2 = useRef(false)
  const [codeText, setCodeText] = useState()
  const [codeNum, setCodeNum] = useState(60)

  let num = codeNum;
  const [time, setTime] = useState('')
  function login(params) {
    setLoading(true)
    post(`${requestUrl}/user/login`, params)
      .then((res) => {
        if (res.code !== 200) {
          Message.error(res.msg)
          return
        }
        Message.success('登录成功')
        setTimeout(() => {
          localStorage.setItem('userStatus', 'login');
          localStorage.setItem('token', res.data);
          window.location.href = '/';
        }, 1000)

      })
      .finally(() => {
        setLoading(false);
      });
  }
  function clear() {
    clearInterval(time); //清除js定时器
    clearInterval(sTim); //清除js定时器
    setCodeStatus(false)
    codeStatus2.current = false
    setCodeText('')
    setCodeNum(60)
    num = codeNum
    setTime('')
    formRef?.current?.setFieldsValue({
      phone: '',
      password: '',
      code: '',
    })
    formPsw?.current?.setFieldsValue({
      email: '',
      password: '',
      code: '',
    })

  }
  function setPwdFtn(parms) {
    setLoading(true)
    post(`${requestUrl}/users/retrievePassword`, parms)
      .then((res) => {
        if (res.code !== 200) {
          Message.error(res.msg)
          return
        }
        Message.success('密码修改成功')
        clear()
        setPsw(false)
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values)
    }).catch(() => { });
  }
  function onPswSubmitClick() {
    formPsw.current.validate().then((values) => {
      setPwdFtn(values)
    }).catch(() => { });
  }
  var sTim = ''
  function codeFtn(type) {
    if (type === 1) {
      if (!phoneTs.test(params.phone)) {
        Message.error('手机号格式不正确')
        return
      }
      codeStatus2.current = true
      post(`${requestUrl}/users/sendPhoneCode`, { phone: params.phone })
        .then((res) => {
          if (res.code !== 200) {
            Message.error(res.msg)
            codeStatus2.current = false
            return
          }
          setCodeStatus(true)
          num--
          setCodeText(num + '秒后重新发送')
          sTim = setInterval(() => {
            numDaojishi()
          }, 1000);
          setTime(sTim)
        })
        .finally(() => {
        });
    } else {
      if (!emailTs.test(params.email)) {
        Message.error('邮箱格式不正确')
        return
      }
      codeStatus2.current = true
      post(`${requestUrl}/users/sendEmail`, { email: params.email })
        .then((res) => {
          if (res.code !== 200) {
            Message.error(res.msg)
            codeStatus2.current = false
            return
          }
          setCodeStatus(true)
          num--
          setCodeText(num + '秒后重新发送')
          sTim = setInterval(() => {
            numDaojishi()
          }, 1000);
          setTime(sTim)
        })
        .finally(() => {
        });
    }

  }

  function formChange(value, values) {
    setParams(values)
  }
  function pwdChange(value, values) {
    setParams(values)
  }
  // 验证码倒计时
  function numDaojishi() {
    num--
    if (num > 0) {
      setCodeText(num + '秒后重新发送')
    } else {
      clearInterval(time); //清除js定时器
      clearInterval(sTim); //清除js定时器
      setCodeStatus(false)
      codeStatus2.current = false
      setCodeText('')
      setCodeNum(60)
      num = codeNum
      setTime('')
    }
  }
 
  return (
    <div className='login_form_container'>
      <div className='login_form_container_left'>
        <div>星火·登记</div>
        <div>星火·登记</div>
        <div>打造链网协同新基建</div>
        <div>建设基于区块链新经济模式</div>
      </div>
      <div className='login_form_container_right'>
        <div className='login_form_container_right_title'>
          {
            !psw ? '欢迎登录' : '找回密码'
          }
        </div>
        {
          !psw ? (
            <Form
              form={form}
              ref={formRef}
              onChange={formChange}
            >
              <Form.Item
                field='phone'
                rules={[{
                  required: true, validateLevel: 'error',
                  validator(value, cb) {
                    if (!value) {
                      return cb('请输入手机号')
                    }
                    if (!phoneTs.test(value)) {
                      return cb('手机号格式不正确')
                    }
                    return cb();
                  }
                }]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  style={{ height: 34, width: 300 }}
                  placeholder='输入手机号'
                  prefix={<IconUser />}
                />
              </Form.Item>

              <Form.Item
                field='password'
                rules={[{ required: true, message: '请输入登录密码' }]}
                style={{ marginBottom: 20 }}
              >
                <Input.Password
                  style={{ height: 34, width: 300 }}
                  placeholder='输入登录密码'
                  prefix={<IconLock />}
                />
              </Form.Item>

              <Form.Item
                field='code'
                rules={[{ required: true, message: '请输入验证码' }]}
                style={{ marginBottom: 28 }}
              >
                <Input
                  style={{ height: 34, width: 300 }}
                  placeholder='输入验证码'
                  prefix={<IconMobile />}
                  suffix={<div className='codeBtn'>
                    {
                      codeStatus ? <span className='codeBtn_zhanshi'>{codeText}</span> : <span className='codeBtn_huoqu' onClick={() => {
                        if(codeStatus2.current){return}
                        codeFtn(1)
                      }}>获取验证码</span>
                    }
                  </div>}
                />
              </Form.Item>
              <Button type='primary' style={{ width: 300, height: 32 }} onClick={onSubmitClick} loading={loading}>
                <span className='btnClass'>登录</span>
              </Button>
              <div className='forget_password'>
                <span onClick={() => {
                  setPsw(true)
                  clear()
                  setLoading(false);
                }}>找回密码</span>
              </div>
            </Form>
          ) : (
            <Form
              form={form}
              ref={formPsw}
              onChange={pwdChange}
            >
              <Form.Item
                field='email'
                rules={[
                  {
                    type: 'email',
                    validateLevel: 'warning',
                    message: '邮箱不是合法的邮箱地址'
                  },
                  {
                    required: true,
                    type: 'string',
                    minLength: 6,
                    message: '邮箱长度不可小于6位'
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  style={{ height: 34, width: 300 }}
                  placeholder='输入安全邮箱'
                  prefix={<IconUser />}
                />
              </Form.Item>
              <Form.Item
                field='code'
                rules={[{ required: true, message: '请输入邮箱验证码' }]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  style={{ height: 34, width: 300 }}
                  placeholder='输入邮箱验证码'
                  prefix={<IconMobile />}
                  suffix={<div className='codeBtn'>
                    {
                      codeStatus ? <span className='codeBtn_zhanshi'>{codeText}</span> : <span className='codeBtn_huoqu' onClick={() => { if(codeStatus2.current){return} codeFtn(2) }}>获取验证码</span>
                    }
                  </div>}
                />
              </Form.Item>
              <Form.Item
                field='password'
                rules={[{ required: true, message: '请输入新密码' }]}
                style={{ marginBottom: 28 }}
              >
                <Input.Password
                  style={{ height: 34, width: 300 }}
                  placeholder='输入新密码'
                  prefix={<IconLock />}
                />
              </Form.Item>
              <Button type='primary' style={{ width: 300, height: 32 }} onClick={onPswSubmitClick} loading={loading}>
                <span className='btnClass'>确定</span>
              </Button>

              <div className='info_class'>若尚未绑定邮箱，请联系管理员找回密码。</div>
              <div className='next_class' onClick={() => {
                setPsw(false)
                clear()
                setLoading(false)

              }}>返回登录</div>
            </Form>
          )
        }
      </div>

      <div className='login_form_container_bottom'>
        Copyright © 2016-2022 天津滨海柜台交易市场登记结算有限公司 京ICP备12003601号-6
      </div>
      <div className='login_form_container_bottom'>
        技术支持：北京泰尔英福科技有限公司
      </div>
    </div>
  );
}
