import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, deletes } from '@/api/request';
import { requestUrl } from '@/config/index';

//  获取溯源购买链接
export const getLinkList = (params) =>
  get(`${requestUrl}/v2/web/cowrieLink/listByPage`, { ...params }).then(
    (res) => res.data
  );

export const getLinkLists = createAsyncThunk(
  'link/getReleaseLists',
  async (params) => {
    const res = await getLinkList(params);
    return res;
  }
);

// 添加购买链接
export const getBatchSave = (params) =>
  post(`${requestUrl}/v2/web/cowrieLink/batchSaveLink`, {...params}).then(
    (res) => res
  );

// 编辑链接
export const getBatchUpdate = (params) =>
  post(`${requestUrl}/v2/web/cowrieLink/update`, { ...params }).then(
    (res) => res
  );

// 删除链接
export const getBatchRemove = (params) =>
  deletes(`${requestUrl}/v2/web/cowrieLink/remove?linkId=${params.linkId}`).then(
    (res) => res
  );

export const linkSlice = createSlice({
  name: 'link',
  initialState: {
    linkList: [],
    linkTotal: 0
  },
  reducers: {},
  extraReducers: {
    [getLinkLists.fulfilled](state, { payload }) {
      state.linkList = payload?.records ? payload?.records : [];
      state.linkTotal = payload?.total ? payload?.total : 0;
    }
  }
});

export default linkSlice.reducer;
