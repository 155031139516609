import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from '@/api/request';
import { requestUrl } from '@/config/index';

// 手机验证码
export const getCode = (params) =>
  get(`${requestUrl}/v2/web/member/captcha`, { ...params }).then((res) => res);

// 添加成员

export const addMember = (params) =>
  post(`${requestUrl}/v2/web/member/addMember`, { ...params }).then(
    (res) => res
  );

// 查询列表
export const getlogLists = (params) =>
  get(`${requestUrl}/v2/web/member/list`, { ...params }).then(
    (res) => res.data
  );

export const getMemberLists = createAsyncThunk(
  'member/getMemberLists',
  async (params) => {
    const res = await getlogLists(params);
    return res;
  }
);
// 移除成员
export const removeMember = (params) =>
  get(`${requestUrl}/v2/web/member/removeMember`, { ...params }).then(
    (res) => res
  );

export const memberSlice = createSlice({
  name: 'memberSlice',
  initialState: {
    memberList: [],
    productTotal: 1,
    releaseList: [],
    releaseTotal: 0,
    productInfoList: [],
    productInfoTotal: 0,
    interactList: [],
    interactTotal: 0
  },
  reducers: {},
  extraReducers: {
    [getMemberLists.fulfilled](state, { payload }) {
      state.memberList =  payload?.records ? payload?.records : []
      state.membertTotal =payload?.total ? payload?.total :1;
    },
    [getMemberLists.rejected]() {},
    [getMemberLists.pending]() {}
  }
});

export default memberSlice.reducer;
