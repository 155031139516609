const layoutSetting = {
  colorWeek: false,
  navbar: false,
  menu: true,
  footer: false,
  themeColor: '#165DFF',
  menuWidth: 192
};

export default layoutSetting;
