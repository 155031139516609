import React, { useState, useRef, useMemo, useEffect, Suspense } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Spin, Message } from '@arco-design/web-react';
import cs from 'classnames';
import {
  IconMenuFold,
  IconMenuUnfold,
  IconHome,
  IconSave,
  IconLock,
  IconSafe,
  IconClose
} from '@arco-design/web-react/icon';
import { getDetails } from '@/store/mineStore/accountDetail'
import warningIcon from './assets/image/warning.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllMenuData } from '@/store/features/menuSlice';
import useRoute from '@/routes';
import qs from 'query-string';
import NProgress from 'nprogress';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import _ from 'lodash';
import getUrlParams from './utils/getUrlParams';
import lazyload from './utils/lazyload';
import styles from './style/layout.module.less';
import { getBouttnFtns } from '@/store/mineStore/permissions';
import { getUsersVerifyIsEmails } from '@/store/mineStore/userManagement/autonymUser'

import { requestUrl } from '@/config/index'
import { post } from '@/api/request';
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const Sider = Layout.Sider;
const Content = Layout.Content;

function getIconFromKey(key) {
  switch (key) {
    case 'system':
      return <IconHome className={styles.icon} />;
    case 'privateKey':
      return <IconSafe className={styles.icon} />;
    case 'commercialUse':
      return <IconLock className={styles.icon} />;
    case 'generate':
      return <IconSave className={styles.icon} />;
    default:
      return <div className={styles['icon-empty']} />;
  }
}




function getFlattenRoutes(routes) {
  const res = [];
  const copyRoutes = _.cloneDeep(routes); // 不能影响原map数据
  function travel(_routes) {
    _routes.forEach((route) => {
      const visibleChildren = (route.children || []).filter(
        (child) => !child.ignore
      );
      if (route.path && (!route.children || !visibleChildren.length)) {
        try {
          if (!route.component) {
            route.component = lazyload(() => import(`./pages/${route.path}`));
          }
          res.push(route);
        } catch (e) {
          console.error(e);
        }
      }
      if (route.children && route.children.length) {
        travel(route.children);
      }
    });
  }
  travel(copyRoutes);
  return res;
}
const oncontextmenu = '/blogService/VendorblogService/detail'
function PageLayout() {
  const dispatch = useDispatch();
  const urlParams = getUrlParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [emailType, setEmailType] = useState(1)
  const pathname = location.pathname;
  const currentComponent = qs.parseUrl(pathname).url.slice(1);
  const { settings, userLoading } = useSelector((state) => state.userInfoSlice);
  const states = useSelector(
    (state) => state.autonymUserSlice
  );
  const userStates = useSelector(
    (state) => state.accountSlice
  );

  const {
    routeData: { sideMenuData }
  } = useSelector((state) => state.menuSlice);

  const [routes, defaultRoute] = useRoute({}, sideMenuData);

  // 监听路由
  useEffect(() => {
    let label = '';
    var reg2 = new RegExp('/'); // 不加'g'，仅删除字符串里第一个"a"
    var str = pathname.replace(reg2, '');
    if (sideMenuData.length > 0) {
      sideMenuData.forEach((item) => {
        (item.children || []).forEach((val) => {
          if (val.path && val.path === str) {
            label = val.label;
          }
        });
      });
      if (label) {
        dispatch(
          getBouttnFtns({
            parentLabel: label
          })
        );
      }
    }
    if (pathname.indexOf('accountDetail') !== -1) {
      setEmailType(2)
    } else {
      setEmailType(1)
    }
  }, [location, sideMenuData]);
  const [breadcrumb, setBreadCrumb] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  const defaultSelectedKeys = [currentComponent || defaultRoute];
  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys);
  const [openKeys, setOpenKeys] = useState([]);
  const routeMap = useRef(new Map());
  const menuMap = useRef(new Map());

  const navbarHeight = 60;
  const menuWidth = collapsed ? 48 : settings.menuWidth;

  // const emailWidth = `calc(100vw - ${menuWidth}px)`
  const showNavbar = settings.navbar && urlParams.navbar !== false;
  const showMenu = settings.menu && urlParams.menu !== false;
  const showFooter = settings.footer && urlParams.footer !== false;

  const flattenRoutes = useMemo(() => getFlattenRoutes(routes) || [], [routes]);

  function renderRoutes() {
    routeMap.current.clear();
    return function travel(_routes = [], level, parentNode = []) {
      return _routes.map((route) => {
        const { breadcrumb = true, ignore } = route;
        const iconDom = getIconFromKey(route.path);
        const titleDom = (
          <>
            {iconDom} {route.name}
          </>
        );

        routeMap.current.set(
          `/${route.path}`,
          breadcrumb ? [...parentNode, route.name] : []
        );

        const visibleChildren = (route.children || []).filter((child) => {
          const { ignore, breadcrumb = true } = child;
          if (ignore || route.ignore) {
            routeMap.current.set(
              `/${child.path}`,
              breadcrumb ? [...parentNode, route.name, child.name] : []
            );
          }

          return !ignore;
        });

        if (ignore) {
          routeMap.current.set(`/${route.path}`, [
            ...(routeMap.current.get(`/${route.parentKey}`) || []),
            route.name
          ]);
          return '';
        }
        if (visibleChildren.length) {
          menuMap.current.set(route.path, { subMenu: true });
          return (
            <SubMenu key={route.path} title={titleDom}>
              {travel(visibleChildren, level + 1, [...parentNode, route.name])}
            </SubMenu>
          );
        }
        menuMap.current.set(route.path, { menuItem: true });
        return <MenuItem key={route.path}>{titleDom}</MenuItem>;
      });
    };
  }

  // 监听回退pathname变更需要根据当前path路径跳转

  function toggleCollapse() {
    setCollapsed((collapsed) => !collapsed);
  }

  const paddingLeft = showMenu ? { paddingLeft: menuWidth } : {};
  const paddingTop = showNavbar ? { paddingTop: navbarHeight } : {};
  const paddingStyle = { ...paddingLeft, paddingTop: 48 };

  function updateMenuStatus() {
    const pathKeys = pathname.split('/');
    const newSelectedKeys = [];
    const newOpenKeys = [...openKeys];
    while (pathKeys.length > 0) {
      const currentRouteKey = pathKeys.join('/');
      const menuKey = currentRouteKey.replace(/^\//, '');
      const menuType = menuMap.current.get(menuKey);
      if (menuType && menuType.menuItem) {
        newSelectedKeys.push(menuKey);
      }
      if (menuType && menuType.subMenu && !openKeys.includes(menuKey)) {
        newOpenKeys.push(menuKey);
      }
      pathKeys.pop();
    }
    setSelectedKeys(newSelectedKeys);
    setOpenKeys(newOpenKeys);
  }
  // let editPswdRef = React.createRef();
  useEffect(() => {
    dispatch(fetchAllMenuData());
    dispatch(getUsersVerifyIsEmails())
    dispatch(getDetails())
  }, []);

  useEffect(() => {
    // debugger;
    const routeConfig = routeMap.current.get(pathname);
    setBreadCrumb(routeConfig || []);
    updateMenuStatus();
  }, [pathname, JSON.stringify(routes)]);

  function onClickMenuItem(key) {
    // debugger;
    const currentRoute = flattenRoutes.find((r) => r.path === key);
    const component = currentRoute.component;
    const preload = component.preload();
    NProgress.start();
    preload.then(() => {
      navigate(currentRoute.path ? currentRoute.path : `/${key}`);
      NProgress.done();
    });
  }

  // 异步加载路由组件
  // const PageException = lazyload(() => import('./pages/exception/403'));

  // 退出登录
  function nextLogin() {

    post(`${requestUrl}/logout`, {}).then(res => {
      if (res.code !== 200) {
        Message.error(res.msg)
        return
      }
      Message.success('退出成功')
      window.location.href = '/login';
      localStorage.setItem('userStatus', '');
      localStorage.setItem('token', '');
    })
  }

  let leftStatus = '1';
  if (pathname.indexOf('dataBigscreen') !== -1) {
    leftStatus = '2';
  } else {
    leftStatus = '1';
  }

  const Initialise = ['/bodyManagement/detail','/productManagement/source','/productManagement/records','/blogService/VendorblogService/recordcommodity','/blogService/VendorblogService/registerList','/adminProductManage/approvalRecord']
  return (
    <Layout className='layout'>

      <div
        className={cs(styles['layout-navbar'], {
          [styles['layout-navbar-hidden']]: !showNavbar
        })}
      >
        <NavBar show={showNavbar} />
      </div>
      {userLoading ? (
        <Spin className='spin' />
      ) : (
        <Layout>
          {/* <div className={styles.title}>数字资产服务平台</div> */}
          {
            leftStatus === '1' && (
              <div className={styles.header}>
                <div className={styles.headerTitle}>
                  <div>星火登记平台</div>
                </div>
                <div className={styles.headerUser}>
                  <div>
                  </div>
                  <div>
                    hi!{userStates?.userInfo?.name}
                  </div>
                </div>

                <div className={styles.user_container}>

                </div>
                <div className={styles.rightBtn}>
                  <div onClick={() => {
                    navigate(`/accountDetail`, {})
                  }}>账户信息</div>
                  <div onClick={() => {
                    nextLogin()
                  }}>退出登录</div>
                </div>
              </div>
            )
          }

          {(showMenu && leftStatus === '1')&& location.pathname !== oncontextmenu && (
            <Sider
              className={styles['layout-sider']}
              width={menuWidth}
              collapsed={collapsed}
              onCollapse={setCollapsed}
              trigger={null}
              collapsible
              breakpoint='xl'
              style={paddingTop}
            >
              <div className={styles['menu-wrapper']}>
                <Menu
                  collapse={collapsed}
                  onClickMenuItem={onClickMenuItem}
                  selectedKeys={selectedKeys}
                  openKeys={openKeys}
                  onClickSubMenu={(_, openKeys) => setOpenKeys(openKeys)}
                >
                  {renderRoutes()(routes, 1)}
                </Menu>
              </div>
              <div className={styles['collapse-btn']} onClick={toggleCollapse}>
                {collapsed ? <IconMenuUnfold /> : <IconMenuFold />}
              </div>
            </Sider>
          )}
          <Layout className={styles['layout-content']} style={leftStatus === '1'&& location.pathname !== oncontextmenu  ? paddingStyle : { padding: 0 }}>
            <div className={styles['layout-content-wrapper']}>
              {
                leftStatus === '1' && location.pathname !== oncontextmenu && (
                  // <div className={styles['layout-content-wrapper-title']}>
                  <div
                    className={cs(( !Initialise.includes(location.pathname) ) ?
                      styles['layout-content-wrapper-title']:styles['layout-content-wrapper-title2']
                    )}
                  >
                    {
                      (!states.emailStatus && emailType === 1) &&
                      // style={{ width: emailWidth }}
                      <div className={styles['bang_email']} >
                        <div className={styles['bang_email_text']}>
                          <img src={warningIcon} />
                          <span className={styles['bang_email_text_span1']}>您尚未绑定安全邮箱，为了账户安全请尽快绑定。</span>
                          <span className={styles['bang_email_text_span2']} onClick={() => {
                            navigate(`/accountDetail`, {
                              state: {
                                email: '123'
                              }
                            })
                          }}>立即绑定</span>
                        </div>
                        <IconClose style={{ cursor: 'pointer' }} onClick={() => { setEmailType(2) }} />
                      </div>
                    }
                    {!!breadcrumb.length && !!sideMenuData.length && (
                      <div className={styles['layout-breadcrumb']}>
                        <Breadcrumb>
                          {breadcrumb.map((node, index) => (
                            <Breadcrumb.Item key={index}>{node}</Breadcrumb.Item>
                          ))}
                        </Breadcrumb>
                        {(!Initialise.includes(location.pathname)) &&<div className={styles['layoutTitle']}>
                            {
                              breadcrumb.length > 2 ? breadcrumb[2] : (breadcrumb.length > 1 ? breadcrumb[1] : breadcrumb[0])
                            }
                          </div>
                        }
                      </div>
                    )}
                  </div>
                )
              }
              <div  className={cs(`${leftStatus === '1'&& location.pathname !== oncontextmenu ?  styles['layout-content-wrapper-content']:styles['layout-content-wrapper-content_02']}`)}>
                <Content>
                  <Routes>
                    {flattenRoutes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          exact
                          path={`/${route.path}`}
                          element={
                            <Suspense
                              fallback={
                                <div className='lazy-load'>Loading...</div>
                              }
                            >
                              <route.component />
                            </Suspense>
                          }
                        />
                      );
                    })}
                    {/* 默认跳转 */}
                    {/* <Route exact path='/' element={<Navigate to={'/home'} />} /> */}
                    <Route
                      exact
                      path='/'
                      element={
                        flattenRoutes.length ? (
                          <Navigate to={flattenRoutes[0]?.path} />
                        ) : (
                          <Spin loading={flattenRoutes}></Spin>
                        )
                      }
                    />
                  </Routes>
                </Content>
              </div>

            </div>
            {showFooter && <Footer />}
          </Layout>


        </Layout>
      )}

    </Layout>
  );
}

export default PageLayout;
