import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  推荐
export const getRecommendList = (params) =>
    get(
        `${requestUrl}/recommend/list`, 
        { ...params }
    ).then((res) => res.data);
export const getRecommendLists = createAsyncThunk(
    'btn/getRecommendLists',
    async (params) => {
        const res = await getRecommendList(params);
        return res;
    }
);
// 推荐下拉
export const getRecommendSupplierList= (params) =>
    get(
        `${requestUrl}/recommend/supplierList`, 
        { ...params }
    ).then((res) => res.data);
export const getRecommendSupplierLists = createAsyncThunk(
    'btn/getRecommendSupplierList',
    async (params) => {
        const res = await getRecommendSupplierList(params);
        return res;
    }
);


export const getrenList = (params) =>
    get(
        `${requestUrl}/recommend/list`, 
        { ...params }
    ).then((res) => res.data);
export const getrenLists = createAsyncThunk(
    'btn/getrenLists',
    async (params) => {
        const res = await getrenList(params);
        return res;
    }
);

// 认证下拉
export const getRecommendSupplierServerList= (params) =>
    get(
        `${requestUrl}/recommend/supplierServerList`, 
        { ...params }
    ).then((res) => res.data);
export const getRecommendSupplierServerLists = createAsyncThunk(
    'btn/getRecommendSupplierServerLists',
    async (params) => {
        const res = await getRecommendSupplierServerList(params);
        return res;
    }
);

export const serverContentSlice = createSlice({
    name: 'serverContent',
    initialState: {
        tuiList: [],
        tuiSelectList:[],
        renList:[],
        renSelectList:[]
    },
    reducers: {},
    extraReducers: {
        [getRecommendLists.fulfilled](state, { payload }) {
            if(payload){
                state.tuiList = payload
            }
        },
        [getRecommendLists.rejected]() { },
        [getRecommendLists.pending]() { },

        [getRecommendSupplierLists.fulfilled](state, { payload }) {
            if(payload){
                state.tuiSelectList = payload.map(item=>{
                    return {
                        label:item.name,
                        value:item.id
                    }
                })
            }
        },
        [getRecommendSupplierLists.rejected]() { },
        [getRecommendSupplierLists.pending]() { },


        
        [getrenLists.fulfilled](state, { payload }) {
            if(payload){
                state.renList = payload
            }
        },
        [getrenLists.rejected]() { },
        [getrenLists.pending]() { },

        [getRecommendSupplierServerLists.fulfilled](state, { payload }) {
            if(payload){
                state.renSelectList = payload.map(item=>{
                    return {
                        label:item.name,
                        value:item.id
                    }
                })
            }
        },
        [getRecommendSupplierServerLists.rejected]() { },
        [getRecommendSupplierServerLists.pending]() { },

    }
});

export default serverContentSlice.reducer;
