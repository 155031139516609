import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '@/api/request';
import defaultSettings from '@/config/layout.js';
import userInfo from '@/config/userinfo';
export const GlobalState = {
  settings: defaultSettings,
  userInfo
  // userLoading: false
};

const loadUserInfoApi = () =>
  post(
    'http://localhost:3000/console-mock/mock/27d479e3-fbf0-4990-b57a-bb0d6c2bb5d8/api/user/userInfo?apipost_id=c18916'
  ).then((res) => res);

// 名称  解决异步请求接口使用 类似于promise 里面有fulfilled reject pending三种状态
export const fetchUserInfoData = createAsyncThunk(
  'userInfo/loadData',
  async () => {
    const res = await loadUserInfoApi();
    return res;
  }
);

const initialState = {
  settings: defaultSettings,
  userInfo
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  // reducers: {
  //   actions: (state) => {

  //   }
  // },
  extraReducers: {
    [fetchUserInfoData.fulfilled](state, { payload }) {
      // payload拿到成功返回的结果
      state.userInfo.userLoading = false;
      state.userInfo.data = payload;
    },
    [fetchUserInfoData.rejected](state) {
      state.userInfo.userLoading = false;
    },
    [fetchUserInfoData.pending](state) {
      state.userInfo.userLoading = true;
    }
  }
});

export const { updateSettings, updateUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
