import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  新增藏品
export const getBouttnFtn = (params) =>
  get(
    `${requestUrl}/menu/getButtonByParentLabel`,
    { ...params }
  ).then((res) => res.data);
export const getBouttnFtns = createAsyncThunk(
  'btn/getBouttnFtn',
  async (params) => {
    const res = await getBouttnFtn(params);
    return res;
  }
);

export const btnSlice = createSlice({
  name: 'btn',
  initialState: {
    btn:1
  },
  reducers: {},
  extraReducers: {
    [getBouttnFtns.fulfilled](state, { payload }) {
      state.btn = payload
    },
    [getBouttnFtns.rejected]() { },
    [getBouttnFtns.pending]() { },
  }
});

export default btnSlice.reducer;
