import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '@/api/request';
import { requestUrl } from '@/config/index'

//  关注记录
export const getProtocolList = (params) =>
  get(
    `${requestUrl}/protocol/list`,  
    { ...params }
  ).then((res) => res.data);
  
export const getProtocolLists = createAsyncThunk(
  'agreement/getProtocolLists',
  async (params) => {
    const res = await getProtocolList(params);
    return res;
  }
);

export const agreementSlice = createSlice({
  name: 'agreement',
  initialState: {
    list:[],
    total:0,
  },
  reducers: {},
  extraReducers: {
    [getProtocolLists.fulfilled](state, { payload }) {
      state.list = payload?.records ? payload?.records : []
      state.total = payload?.total ? payload?.total : 0
    },
    [getProtocolLists.rejected]() { },
    [getProtocolLists.pending]() { },
  }
});

export default agreementSlice.reducer;
